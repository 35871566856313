import { Injectable } from '@angular/core';
import { UsersHttpService } from '@core/http/users.http.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AnalyticsService } from '@core/services/analytics.service';
import { SnackbarService } from '@shared/snack-bar/snack-bar.service';
import { parseError } from '@state/errors.parser';
import { of } from 'rxjs';
import { catchError, delay, map, switchMap } from 'rxjs/operators';
import * as usersActions from './users.actions';

@Injectable()
export class UsersEffects {
  constructor(
    private actions$: Actions,
    private http: UsersHttpService,
    private snackService: SnackbarService,
    private analyticsService: AnalyticsService
  ) {}

  getForeigners$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.getForeigners),
      switchMap(({ payload }) => {
        const { all, count, ...rest } = payload;
        return this.http.searchForeigners(rest).pipe(
          map(foreigners => {
            return usersActions.getUsersSuccess({ users: foreigners });
          }),
          catchError(error => {
            const errorMessage = parseError(error, usersActions.getForeigners.type);
            this.snackService.showError(errorMessage);
            return of(usersActions.getUsersError({ errorMessage }));
          })
        );
      })
    )
  );

  getEmployees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.getEmployees),
      switchMap(({ payload }) => {
        const { all, count, ...rest } = payload;
        return this.http.searchEmployees(rest).pipe(
          map(employees => {
            return usersActions.getUsersSuccess({ users: employees });
          }),
          catchError(error => {
            const errorMessage = parseError(error, usersActions.getEmployees.type);
            this.snackService.showError(errorMessage);
            return of(usersActions.getUsersError({ errorMessage }));
          })
        );
      })
    )
  );

  getManagers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.getManagers),
      switchMap(({ payload }) => {
        const { all, count, ...rest } = payload;
        return this.http.searchManagers(rest).pipe(
          map(managers => {
            return usersActions.getUsersSuccess({ users: managers });
          }),
          catchError(error => {
            const errorMessage = parseError(error, usersActions.getManagers.type);
            this.snackService.showError(errorMessage);
            return of(usersActions.getUsersError({ errorMessage }));
          })
        );
      })
    )
  );

  getCompanyEmployees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.getCompanyEmployees),
      switchMap(({ payload }) => {
        const { all, count, ...rest } = payload;
        return this.http.searchMyCompanyEmployees(rest).pipe(
          map(companyEmployees => {
            return usersActions.getUsersSuccess({ users: companyEmployees });
          }),
          catchError(error => {
            const errorMessage = parseError(error, usersActions.getCompanyEmployees.type);
            this.snackService.showError(errorMessage);
            return of(usersActions.getUsersError({ errorMessage }));
          })
        );
      })
    )
  );

  getUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.getUsers),
      switchMap(({ payload }) => {
        const { all, count, ...rest } = payload;
        return this.http.searchUsers(rest).pipe(
          map(users => {
            return usersActions.getUsersSuccess({ users });
          }),
          catchError(error => {
            const errorMessage = parseError(error, usersActions.getUsers.type);
            this.snackService.showError(errorMessage);
            return of(usersActions.getUsersError({ errorMessage }));
          })
        );
      })
    )
  );

  selfRegister$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.selfRegister),
      switchMap(({ opts }) => {
        return this.http.selfRegister(opts).pipe(
          map(() => {
            this.analyticsService.trackEvent('user_event', 'user_created_new_account');
            return usersActions.selfRegisterSuccess();
          }),
          catchError(error => {
            let errorMessage = error?.error?.message;
            if (!errorMessage) {
              errorMessage = parseError(error, usersActions.getUsers.type);
            }
            return of(usersActions.selfRegisterError({ errorMessage }));
          })
        );
      })
    )
  );

  getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.getUser),
      switchMap(({ userId }) => {
        return this.http.getUser(userId).pipe(
          map(user => {
            return usersActions.getUserSuccess({ user });
          }),
          catchError(error => {
            const errorMessage = parseError(error, usersActions.getUser.type);
            this.snackService.showError(errorMessage);
            return of(usersActions.getUserError({ errorMessage }));
          })
        );
      })
    )
  );

  createForeigner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.createForeigner),
      switchMap(({ payload }) => {
        return this.http.createForeigner(payload).pipe(
          map(({ user, emailSent }) => {
            if (emailSent) {
              this.snackService.showInfo('AUTH.ACTIVATION-EMAIL-SENT');
            } else {
              this.snackService.showError('MANAGEMENT.FOREIGNERS.ACCOUNT-CREATED-BUT-EMAIL-SENDING-FAILED');
            }
            return usersActions.createUserSuccess({ createdUser: user });
          }),
          catchError(error => {
            const errorMessage = parseError(error, usersActions.createForeigner.type);
            this.snackService.showError(errorMessage);
            return of(usersActions.createUserError({ errorMessage }));
          })
        );
      })
    )
  );

  createEmployee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.createEmployee),
      switchMap(({ payload }) => {
        return this.http.createEmployee(payload).pipe(
          map(({ user, emailSent }) => {
            if (emailSent) {
              this.snackService.showInfo('AUTH.ACTIVATION-EMAIL-SENT');
            } else {
              this.snackService.showError('MANAGEMENT.EMPLOYEES.ACCOUNT-CREATED-BUT-EMAIL-SENDING-FAILED');
            }
            return usersActions.createUserSuccess({ createdUser: user });
          }),
          catchError(error => {
            const errorMessage = parseError(error, usersActions.createEmployee.type);
            this.snackService.showError(errorMessage);
            return of(usersActions.createUserError({ errorMessage }));
          })
        );
      })
    )
  );

  createManager$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.createManager),
      switchMap(({ payload }) => {
        return this.http.createManager(payload).pipe(
          map(({ user, emailSent }) => {
            if (emailSent) {
              this.snackService.showInfo('AUTH.ACTIVATION-EMAIL-SENT');
            } else {
              this.snackService.showError('MANAGEMENT.EMPLOYEES.ACCOUNT-CREATED-BUT-EMAIL-SENDING-FAILED');
            }
            return usersActions.createUserSuccess({ createdUser: user });
          }),
          catchError(error => {
            const errorMessage = parseError(error, usersActions.createManager.type);
            this.snackService.showError(errorMessage);
            return of(usersActions.createUserError({ errorMessage }));
          })
        );
      })
    )
  );

  updateEmployee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.updateEmployee),
      switchMap(({ payload, userId }) => {
        return this.http.updateEmployee(payload, userId).pipe(
          map(updatedUser => {
            this.snackService.showInfo('MANAGEMENT.USER_UPDATE_SUCCESS');
            return usersActions.updateUserSuccess({ updatedUser });
          }),
          catchError(error => {
            const errorMessage = parseError(error, usersActions.updateEmployee.type);
            this.snackService.showError(errorMessage);
            return of(usersActions.updateUserError({ errorMessage }));
          })
        );
      })
    )
  );

  updateForeigner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.updateForeigner),
      switchMap(({ payload, userId }) => {
        return this.http.updateForeigner(payload, userId).pipe(
          map(updatedUser => {
            this.snackService.showInfo('MANAGEMENT.USER_UPDATE_SUCCESS');
            return usersActions.updateUserSuccess({ updatedUser });
          }),
          catchError(error => {
            const errorMessage = parseError(error, usersActions.updateForeigner.type);
            this.snackService.showError(errorMessage);
            return of(usersActions.updateUserError({ errorMessage }));
          })
        );
      })
    )
  );

  updateManager$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.updateManager),
      switchMap(({ payload, userId }) => {
        return this.http.updateManager(payload, userId).pipe(
          map(updatedUser => {
            this.snackService.showInfo('MANAGEMENT.USER_UPDATE_SUCCESS');
            return usersActions.updateUserSuccess({ updatedUser });
          }),
          catchError(error => {
            const errorMessage = parseError(error, usersActions.updateManager.type);
            this.snackService.showError(errorMessage);
            return of(usersActions.updateUserError({ errorMessage }));
          })
        );
      })
    )
  );

  removeForeigner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.removeForeigner),
      switchMap(({ userId }) => {
        return this.http.removeForeigner(userId).pipe(
          map(({ success }) => {
            if (success) {
              this.snackService.showInfo('MANAGEMENT.FOREIGNERS.FOREIGNER-REMOVED');
              return usersActions.removeUserSuccess({ removedUserId: userId });
            } else {
              this.snackService.showError('MANAGEMENT.FOREIGNERS.ERROR-REMOVING-FOREIGNER');
              return usersActions.removeUserError({ errorMessage: 'FOREIGNERS.ERROR-REMOVING-FOREIGNER' });
            }
          }),
          catchError(error => {
            const errorMessage = parseError(error, usersActions.removeForeigner.type);
            this.snackService.showError(errorMessage);
            return of(usersActions.removeUserError({ errorMessage }));
          })
        );
      })
    )
  );

  restoreUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.restoreUser),
      switchMap(({ userId }) => {
        return this.http.restoreUser(userId).pipe(
          map(restoredUser => {
            return usersActions.restoreUserSuccess({ restoredUser });
          }),
          catchError(error => {
            const errorMessage = parseError(error, usersActions.restoreUser.type);
            this.snackService.showError(errorMessage);
            return of(usersActions.restoreUserError({ errorMessage }));
          })
        );
      })
    )
  );

  restoreForeigner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.restoreForeigner),
      switchMap(({ foreignerId }) => {
        return this.http.restoreForeigner(foreignerId).pipe(
          map(restoredUser => {
            return usersActions.restoreUserSuccess({ restoredUser });
          }),
          catchError(error => {
            const errorMessage = parseError(error, usersActions.restoreForeigner.type);
            this.snackService.showError(errorMessage);
            return of(usersActions.restoreUserError({ errorMessage }));
          })
        );
      })
    )
  );

  removeEmployee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.removeEmployee),
      switchMap(({ userId }) => {
        return this.http.removeUser(userId).pipe(
          map(() => {
            this.snackService.showInfo('MANAGEMENT.EMPLOYEES.EMPLOYEE-REMOVED');
            return usersActions.removeUserSuccess({ removedUserId: userId });
          }),
          catchError(error => {
            const errorMessage = parseError(error, usersActions.removeEmployee.type);
            this.snackService.showError(errorMessage);
            return of(usersActions.removeUserError({ errorMessage }));
          })
        );
      })
    )
  );

  removeManager$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.removeManager),
      switchMap(({ userId }) => {
        return this.http.removeManager(userId).pipe(
          map(() => {
            this.snackService.showInfo('MANAGEMENT.COMPANIES.MANAGER-REMOVED');
            return usersActions.removeUserSuccess({ removedUserId: userId });
          }),
          catchError(error => {
            const errorMessage = parseError(error, usersActions.removeManager.type);
            this.snackService.showError(errorMessage);
            return of(usersActions.removeUserError({ errorMessage }));
          })
        );
      })
    )
  );

  resendForeignerActivation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.resendForeignerActivation),
      switchMap(({ email, userId }) => {
        return this.http.resendForeignerActivation(email, userId).pipe(
          map(({ emailSent }) => usersActions.resendActivationSuccess({ emailSent })),
          catchError(error => {
            const errorMessage = parseError(error, usersActions.resendForeignerActivation.type);
            return of(usersActions.resendActivationError({ errorMessage }));
          })
        );
      })
    )
  );

  resendEmployeeActivation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.resendEmployeeActivation),
      switchMap(({ email, userId }) => {
        return this.http.resendEmployeeActivation(email, userId).pipe(
          map(({ emailSent }) => usersActions.resendActivationSuccess({ emailSent })),
          catchError(error => {
            const errorMessage = parseError(error, usersActions.resendEmployeeActivation.type);
            return of(usersActions.resendActivationError({ errorMessage }));
          })
        );
      })
    )
  );

  resendMangerActivation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.resendManagerActivation),
      switchMap(({ email, userId }) => {
        return this.http.resendManagerActivation(email, userId).pipe(
          map(({ emailSent }) => usersActions.resendActivationSuccess({ emailSent })),
          catchError(error => {
            const errorMessage = parseError(error, usersActions.resendManagerActivation.type);
            return of(usersActions.resendActivationError({ errorMessage }));
          })
        );
      })
    )
  );

  getEmployeeTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.getEmployeeTypes),
      switchMap(() => {
        return this.http.getEmployeeTypes().pipe(
          map(roles => usersActions.getEmployeeTypesSuccess({ employeeTypes: roles })),
          catchError(() => {
            this.snackService.showError('ERROR_FETCHING_EMPLOYEE_TYPES');
            return of(usersActions.getEmployeeTypesError());
          })
        );
      })
    )
  );

  getEAllRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.getAllRoles),
      switchMap(() => {
        return this.http.getAllRoles().pipe(
          map(roles => usersActions.getAllRolesSuccess({ allRoles: roles })),
          catchError(() => {
            this.snackService.showError('ERROR_FETCHING_EMPLOYEE_TYPES');
            return of(usersActions.getAllRolesError());
          })
        );
      })
    )
  );

  reassignForeigners$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.reassignForeigners),
      switchMap(({ opts }) => {
        return this.http.reassignForeigners(opts).pipe(
          delay(1000),
          map(() => usersActions.reassignForeignersSuccess()),
          catchError(() => {
            this.snackService.showError('Błąd przepisywania obcokrajowców!');
            return of(usersActions.reassignForeignersError());
          })
        );
      })
    )
  );
}
