import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';

import { SingleFileUploadResult, UserAsset } from '@interfaces';

import { UserAssetsHttpService } from '@core/http/user-assets.http.service';
import { parseError } from '@state/errors.parser';
import * as actions from './user-assets.actions';
import { GetFileService } from '@core/services/get-file.service';

@Injectable()
export class UserAssetsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly nzMessage: NzMessageService,
    private readonly translateService: TranslateService,
    private readonly userAssetsHttp: UserAssetsHttpService,
    private readonly getFileService: GetFileService
  ) {}

  private showError(key: string, params?: any): void {
    const msg = this.translateService.instant(key, params || {});
    this.nzMessage.error(msg, { nzDuration: 5000 });
  }

  removeMyAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.removeMyAsset),
      switchMap(({ opts }) => {
        return this.userAssetsHttp.removeMyAsset({ userAssetId: opts.userAssetId }).pipe(
          map(() => {
            return actions.removeMyAssetSuccess({ opts });
          }),
          catchError(() => {
            this.nzMessage.error(this.translateService.instant('NT3.ERROR_REMOVING_ASSET'));
            return of(actions.removeMyAssetError({ errorRemovingAssetId: opts.userAssetId }));
          })
        );
      })
    )
  );

  downloadMyAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.downloadMyAsset),
      switchMap(({ userAssetId }) => {
        return this.userAssetsHttp.downloadMyAsset({ userAssetId }).pipe(
          map(response => {
            if (this.getFileService.getFile(response)) {
              return actions.downloadMyAssetSuccess({ userAssetId });
            } else {
              return actions.downloadMyAssetError({ userAssetId });
            }
          }),
          catchError(() => {
            this.nzMessage.error(this.translateService.instant('NT3.ERROR_DOWNLOADING_ATTACHMENT'));
            return of(actions.downloadMyAssetError({ userAssetId }));
          })
        );
      })
    )
  );

  uploadPreSurveyAttachments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.uploadPreSurveyAttachments),
      concatMap(({ opts }) => {
        const { userProcessId, files, preSurveyId } = opts;
        const resultsObj: { [key: string]: Observable<SingleFileUploadResult> } = files.reduce(
          (acc, currFile) => {
            acc[currFile.fileOriginalName] = this.userAssetsHttp
              .uploadPreSurveyAttachment({ preSurveyId, userProcessId, file: currFile.file })
              .pipe(
                map(uploadResult => {
                  return { errorMessage: null, uploaded: true, userAsset: uploadResult.attachment };
                }),
                catchError(error => {
                  this.showError('NT2.ERROR_UPLOADING_FILE', { fileName: currFile.fileOriginalName });
                  const errorMessage = parseError(error, actions.uploadPreSurveyAttachments.type);
                  return of({
                    errorMessage,
                    uploaded: false,
                    userAsset: { fileOriginalName: currFile.fileOriginalName } as UserAsset,
                  });
                })
              );
            return acc;
          },
          {} as { [key: string]: Observable<SingleFileUploadResult> }
        );

        return forkJoin(resultsObj).pipe(
          map(uploadedFiles => {
            return actions.uploadPreSurveyAttachmentsSuccess({ opts: { preSurveyId, userProcessId, uploadedFiles } });
          }),
          catchError(() => {
            return of(actions.uploadPreSurveyAttachmentsError());
          })
        );
      })
    )
  );
}
