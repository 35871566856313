import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { FontSizeToPx, FontSize, langKeyToLocale, defaultLanguages } from '@constants';
import { LocalStorageService } from '@core/services/local-storage.service';
import { Language, ROLES_KEYS } from '@interfaces';
import { AvailableLanguages } from '@constants';
import { TranslateService } from '@ngx-translate/core';
import { CommonsFacade } from '@state/commons';
import { UserAuthFacade } from '@state/user-auth';
import { Moment } from 'moment';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AnalyticsService } from '@core/services/analytics.service';

@Component({
  selector: 'app-utils-buttons',
  templateUrl: './utils-buttons.component.html',
  styleUrls: ['./utils-buttons.component.scss'],
})
export class UtilsButtonsComponent implements OnInit, OnDestroy {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  @Input() theme: 'LIGHT' | 'DARK' = 'DARK';

  @Output() userLangChanged: EventEmitter<Language> = new EventEmitter();

  public currentUserLang: Language;
  public availableLanguages: Language[] = [];

  constructor(
    private readonly ls: LocalStorageService,
    private readonly translateService: TranslateService,
    private readonly userAuthFacade: UserAuthFacade,
    private readonly commonsFacade: CommonsFacade,
    private readonly dateAdapter: DateAdapter<Moment>,
    private readonly analyticsService: AnalyticsService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  ngOnInit(): void {
    this.commonsFacade.getLanguagesError$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      // if availableLanguages && currentUserLang set then we've loaded langs atleast once
      if (this.availableLanguages?.length && this.currentUserLang) {
        return;
      }
      // if not - use defaults
      this.availableLanguages = [...defaultLanguages];
      this.setupLang();
    });

    this.commonsFacade
      .getLanguages$()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(langs => {
        this.availableLanguages = langs;
        this.setupLang();
      });

    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(({ lang: langKey, translations }) => {
      if (!this.availableLanguages?.length) {
        return;
      }
      const lang = this.availableLanguages.find(({ key }) => key === langKey);

      if (!lang) {
        console.error('User Language changed into one that does not exist in availableLanguages collection');
        return;
      }

      this.currentUserLang = lang;
      const locale = langKeyToLocale[langKey as AvailableLanguages];

      if (locale) {
        this.dateAdapter.setLocale(locale);
      } else {
        this.dateAdapter.setLocale('en-gb');
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private setupLang(): void {
    const userLangInLS = this.ls.getUserLang();
    const defaultLang = this.availableLanguages.find(({ key }) => key === 'en');
    const langToUse = userLangInLS?.key ? userLangInLS : defaultLang;

    this.translateService.use(langToUse.key);
    this.currentUserLang = langToUse;

    if (langToUse.key === 'pl') {
      this.dateAdapter.setLocale('pl-PL');
    }
    if (langToUse.key !== 'pl') {
      this.dateAdapter.setLocale('en-GB');
    }
  }

  public changeFontSize(): void {
    const currFontSizeVal = this.document.documentElement.style.getPropertyValue('--font-factor') || '0px';
    let nextFontSizeVal;

    if (currFontSizeVal === FontSizeToPx[FontSize.SMALL]) {
      nextFontSizeVal = FontSizeToPx[FontSize.MEDIUM];
    }
    if (currFontSizeVal === FontSizeToPx[FontSize.MEDIUM] || !currFontSizeVal || currFontSizeVal === 'undefined') {
      nextFontSizeVal = FontSizeToPx[FontSize.LARGE];
    }
    if (currFontSizeVal === FontSizeToPx[FontSize.LARGE]) {
      nextFontSizeVal = FontSizeToPx[FontSize.SMALL];
    }

    this.document.documentElement.style.setProperty('--font-factor', nextFontSizeVal);
  }

  public changeLangTo(lang: Language): void {
    this.userLangChanged.emit(lang);
    this.userAuthFacade.myself$.pipe(take(1), takeUntil(this.destroy$)).subscribe(myself => {
      this.translateService.use(lang.key);
      this.ls.setUserLang(lang);
      if (!myself?.role?.key) {
        return;
      }

      const currLangId = lang.id;
      if (!currLangId) {
        return;
      }

      this.analyticsService.trackEvent('user_event', 'user_changed_language', {
        from: myself.languageId,
        to: lang.id,
      });

      // different API endpoints for different roles
      if (myself.role.key !== ROLES_KEYS.Admin) {
        this.userAuthFacade.updateMyself({ languageId: currLangId });
        return;
      }

      if (myself?.role.key === ROLES_KEYS.Admin) {
        this.userAuthFacade.updateAdminself(myself.id as string, { languageId: currLangId });
        return;
      }
    });
  }
}
