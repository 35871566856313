import { Component, Inject, OnInit } from '@angular/core';
import { ReplaySubject, take, takeUntil } from 'rxjs';
import { AvailableLanguages } from '@constants';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonsFacade } from '@state/commons';
import { TranslateUtilsService } from '@core/services/translate-utils.service';
import { ManagementFacade } from '@state/management';
import { EmailHTMLNotification, FollowUpEmailMode, Language, User } from '@interfaces';
import { ConfirmationModalService } from '@shared/confirmation-modal/confirmation-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environment';
import { animate, state, style, transition, trigger } from '@angular/animations';

type MessageTranslations = {
  [key: string]: string;
};

@Component({
  selector: 'app-follow-up-email-modal',
  templateUrl: './follow-up-email-modal.component.html',
  styleUrls: ['./follow-up-email-modal.component.scss'],
  animations: [
    trigger('expandCollapse', [
      state('collapsed', style({ height: '0px', overflow: 'hidden', opacity: 0 })),
      state('expanded', style({ height: '*', overflow: 'hidden', opacity: 1 })),
      transition('collapsed <=> expanded', [animate('300ms ease-in-out')]),
    ]),
  ],
})
export class FollowUpEmailModalComponent implements OnInit {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  public appName = environment.APP_NAME;
  public appURL = environment.APP_URL;

  public showAdvancedFields = false;

  public messageKeysPrefix = 'FOLLOW_UP_TEXT_EMAIL_';

  allFieldsKeys: string[] = [
    'TITLE_VALUE',
    'WELCOME_VALUE',
    'BUTTON_VALUE',
    'BUTTON_NOT_WORK_VALUE',
    'MESSAGE_VALUE',
    'FOOTER_VALUE',
  ];

  public messageTranslations: MessageTranslations = this.allFieldsKeys.reduce((acc, fieldKey) => {
    acc[fieldKey] = '';
    return acc;
  }, {} as MessageTranslations);

  public selectedLang: AvailableLanguages;
  public prevLang: AvailableLanguages;

  public availableLanguages: Language[] = [];

  constructor(
    public dialogRef: MatDialogRef<FollowUpEmailModalComponent>,
    private readonly commonsFacade: CommonsFacade,
    private readonly translateUtilsService: TranslateUtilsService,
    private readonly managementFacade: ManagementFacade,
    private readonly confirmation: ConfirmationModalService,
    private readonly translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      users: User[];
      mode: FollowUpEmailMode;
    }
  ) {}

  ngOnInit(): void {
    this.commonsFacade
      .getLanguages$()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(langs => {
        this.availableLanguages = langs;
      });

    this.selectedLang = 'en';
    this.prevLang = 'en';
    this.onChangeLanguage(this.selectedLang);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  public onChangeLanguage(lang: AvailableLanguages): void {
    this.allFieldsKeys.forEach(fieldKey => {
      let translatedText: { [key: string]: string };
      if (this.data.mode === FollowUpEmailMode.SUPPORT_CHAT) {
        translatedText = this.translateUtilsService.getTranslations(
          `FOLLOW_UP.SUPPORT_CHAT.${this.messageKeysPrefix}${fieldKey}`
        );
      }
      if (this.data.mode === FollowUpEmailMode.BACK_OFFICE) {
        translatedText = this.translateUtilsService.getTranslations(
          `FOLLOW_UP.BACK_OFFICE.${this.messageKeysPrefix}${fieldKey}`
        );
      }

      this.messageTranslations[fieldKey] =
        translatedText && translatedText[lang] ? translatedText[lang] : `MISSING_TRANSLATION_${fieldKey}`;
    });

    this.messageTranslations['TITLE_VALUE'] = this.appName + this.messageTranslations['TITLE_VALUE'];
    this.messageTranslations['FOOTER_VALUE'] = this.messageTranslations['FOOTER_VALUE'] + this.appName;
  }

  public sendMessage(): void {
    const baseMessageContent: EmailHTMLNotification = {
      subject: this.messageTranslations['TITLE_VALUE'],
      body_as_text: this.messageTranslations['MESSAGE_VALUE'],
      body_as_html: {
        title: this.messageTranslations['WELCOME_VALUE'],
        subtitle: this.messageTranslations['MESSAGE_VALUE'],
        cta_button_text: this.messageTranslations['BUTTON_VALUE'],
        cta_button_not_working_text: this.messageTranslations['BUTTON_NOT_WORK_VALUE'],
        sub: this.messageTranslations['FOOTER_VALUE'],
      },
    };

    this.managementFacade.sendFollowUpEmailSuccess$.pipe(take(1), takeUntil(this.destroy$)).subscribe(() => {
      this.closeDialog();
    });

    this.managementFacade.sendFollowUpEmailError$.pipe(take(1), takeUntil(this.destroy$)).subscribe(() => {
      this.closeDialog();
    });

    const userIds = this.data.users.map(user => user.id);
    this.managementFacade.sendFollowUpEmail(userIds, baseMessageContent);
  }
  public confirmChangeLanguageDialog(lang: AvailableLanguages) {
    this.confirmation
      .open({
        confirmationToTranslate: 'FOLLOW_UP.FOLLOW_UP_TEXT_EMAIL_RESET_CONFIRM',
        translateParams: { language: this.translateService.instant('LANGUAGE.' + lang.toUpperCase()) },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (!result) {
          this.selectedLang = this.prevLang;
          return;
        }
        this.onChangeLanguage(lang);
        this.prevLang = lang;
      });
  }

  toggleAdvancedFields(): void {
    this.showAdvancedFields = !this.showAdvancedFields;
  }
}
