import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import {
  AddPartnerPointsOpts,
  CancelPayoutOpts,
  ChangePayoutStatusOpts,
  ChangePointsStatusOpts,
  DiscountCode,
  EditPartnerCodeOpts,
  PaginatedResponse,
  PartnerGlobalTerms,
  PartnerPoints,
  PartnerPointsPayout,
  Purchase,
  RemovePartnerPointsOpts,
  SearchPartnersOpts,
  SearchPayoutsOpts,
  SumOfPartnerPoints,
  UpdateGlobalTermsOpts,
  User,
} from '@interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PartnerCodesHttpService {
  private API_URL = environment.API_URL;
  private PARTNER_CODES_URL = `${this.API_URL}/partner-codes`;
  private MANAGEMENT_PARTNER_CODES_URL = `${this.API_URL}/partner-codes/management`;

  constructor(private http: HttpClient) {}

  public generatePartnerCodeForUser(userId: string): Observable<DiscountCode> {
    const url = `${this.MANAGEMENT_PARTNER_CODES_URL}/code-of-partner/${userId}`;
    return this.http.post<DiscountCode>(url, {});
  }

  public getCodeOfPartner(userId: string): Observable<DiscountCode> {
    const url = `${this.MANAGEMENT_PARTNER_CODES_URL}/code-of-partner/${userId}`;
    return this.http.get<DiscountCode>(url);
  }

  public editCodeOfPartner(opts: EditPartnerCodeOpts): Observable<DiscountCode> {
    const { userId, ...body } = opts;
    const url = `${this.MANAGEMENT_PARTNER_CODES_URL}/code-of-partner/${userId}`;
    return this.http.patch<DiscountCode>(url, body);
  }

  public regenerateCodeForPartner(userId: string): Observable<DiscountCode> {
    const url = `${this.MANAGEMENT_PARTNER_CODES_URL}/code-of-partner/${userId}/regenerate-code`;
    return this.http.get<DiscountCode>(url);
  }

  public setDifferentCodeForPartner(userId: string, code: string): Observable<DiscountCode> {
    const url = `${this.MANAGEMENT_PARTNER_CODES_URL}/code-of-partner/${userId}/set-different-code`;
    return this.http.patch<DiscountCode>(url, { code });
  }

  public getPartnerPointsSum(userId: string): Observable<SumOfPartnerPoints> {
    const url = `${this.MANAGEMENT_PARTNER_CODES_URL}/code-of-partner/${userId}/sum-of-points`;
    return this.http.get<SumOfPartnerPoints>(url);
  }

  public getPartnerCodeUsage(userId: string): Observable<Partial<Purchase[]>> {
    const url = `${this.MANAGEMENT_PARTNER_CODES_URL}/code-of-partner/${userId}/usage`;
    return this.http.get<Purchase[]>(url);
  }

  public getPartnerPayoutsHistory(userId: string): Observable<PartnerPointsPayout[]> {
    const url = `${this.MANAGEMENT_PARTNER_CODES_URL}/code-of-partner/${userId}/payouts-history`;
    return this.http.get<PartnerPointsPayout[]>(url);
  }

  public changePayoutStatus(opts: ChangePayoutStatusOpts): Observable<PartnerPointsPayout> {
    const url = `${this.MANAGEMENT_PARTNER_CODES_URL}/code-of-partner/${opts.userId}/payout/${opts.payoutId}/change-status`;
    return this.http.patch<PartnerPointsPayout>(url, { status: opts.status });
  }

  public cancelPayout(opts: CancelPayoutOpts): Observable<{ success: boolean }> {
    const url = `${this.MANAGEMENT_PARTNER_CODES_URL}/code-of-partner/${opts.userId}/payout/${opts.payoutId}`;
    return this.http.delete<{ success: boolean }>(url);
  }

  public getAllPartnerPoints(userId: string): Observable<PartnerPoints[]> {
    const url = `${this.MANAGEMENT_PARTNER_CODES_URL}/code-of-partner/${userId}/all-points-list`;
    return this.http.get<PartnerPoints[]>(url);
  }

  public changePointsStatus(opts: ChangePointsStatusOpts): Observable<PartnerPoints> {
    const url = `${this.MANAGEMENT_PARTNER_CODES_URL}/code-of-partner/${opts.userId}/points/${opts.pointsId}/change-status`;
    return this.http.patch<PartnerPoints>(url, { status: opts.status });
  }

  public addPartnerPointsManually(opts: AddPartnerPointsOpts): Observable<PartnerPoints> {
    const url = `${this.MANAGEMENT_PARTNER_CODES_URL}/code-of-partner/${opts.userId}/points`;
    return this.http.post<PartnerPoints>(url, { quantity: opts.quantity });
  }

  public removePartnerPoints(opts: RemovePartnerPointsOpts): Observable<{ removedPointsId: string }> {
    const url = `${this.MANAGEMENT_PARTNER_CODES_URL}/code-of-partner/${opts.partnerId}/points/${opts.pointsId}`;
    return this.http.delete<{ removedPointsId: string }>(url);
  }

  public searchPartners(payload: SearchPartnersOpts): Observable<PaginatedResponse<Partial<User>>> {
    const url = `${this.MANAGEMENT_PARTNER_CODES_URL}/search-partners`;
    const { all, count, ...body } = payload;
    return this.http.post<PaginatedResponse<Partial<User>>>(url, body);
  }

  public searchPayouts(payload: SearchPayoutsOpts): Observable<PaginatedResponse<PartnerPointsPayout>> {
    const url = `${this.MANAGEMENT_PARTNER_CODES_URL}/search-payouts`;
    const { all, count, ...body } = payload;
    return this.http.post<PaginatedResponse<PartnerPointsPayout>>(url, body);
  }

  public getGlobalTerms(): Observable<PartnerGlobalTerms> {
    const url = `${this.MANAGEMENT_PARTNER_CODES_URL}/global-terms`;
    return this.http.get<PartnerGlobalTerms>(url);
  }

  public updateGlobalTerms(opts: UpdateGlobalTermsOpts): Observable<PartnerGlobalTerms> {
    const url = `${this.MANAGEMENT_PARTNER_CODES_URL}/global-terms`;
    return this.http.patch<PartnerGlobalTerms>(url, opts);
  }
}
