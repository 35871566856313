import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DeviceInfoService } from '@core/services/device-info.service';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { MyPartnerAccountDrawerComponent } from './my-partner-account-drawer.component';

export interface MyPartnerAccountDrawerOpenOpts {
  userName: string;
}

@Injectable({
  providedIn: 'root',
})
export class MyPartnerAccountDrawerService {
  constructor(
    private readonly drawerService: NzDrawerService,
    private readonly translateService: TranslateService,
    private readonly deviceInfoService: DeviceInfoService
  ) {}

  public open(opts: MyPartnerAccountDrawerOpenOpts): NzDrawerRef {
    const deviceType = this.deviceInfoService.getDeviceType();

    const nzPlacement = deviceType === 'DESKTOP' ? 'left' : 'top';
    const nzWidth = deviceType === 'DESKTOP' ? '756px' : '100vw';

    return this.drawerService.create<MyPartnerAccountDrawerComponent, MyPartnerAccountDrawerOpenOpts, any>({
      nzTitle: this.translateService.instant('PARTNER_ACCOUNT.YOUR_PARTNER_ACCOUNT'),
      nzClosable: true,
      nzContent: MyPartnerAccountDrawerComponent,
      nzContentParams: { ...opts },
      nzCloseOnNavigation: true,
      nzMask: true,
      nzMaskClosable: true,
      nzSize: 'large',
      nzHeight: '100dvh',
      nzBodyStyle: {
        'padding-top': '5px',
      },
      nzWrapClassName: 'my-partner-account-drawer',
      nzWidth,
      nzPlacement,
    });
  }
}
