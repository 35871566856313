import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '@core/services/local-storage.service';
import { AnalyticsService } from '@core/services/analytics.service';

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss'],
})
export class CookieBannerComponent implements OnInit {
  public cookieStatus = true;

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.cookieStatus = this.getCookie();
    if (this.cookieStatus) {
      this.analyticsService.allConsentGranted();
    }
  }

  public setCookie(): void {
    this.localStorageService.setCookieBanner(true);
    this.cookieStatus = true;
    this.analyticsService.allConsentGranted();
  }

  public getCookie(): boolean {
    return this.localStorageService.getCookieBanner();
  }
}
