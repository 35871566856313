import {
  Directive,
  Input,
  TemplateRef,
  ElementRef,
  OnInit,
  HostListener,
  ComponentRef,
  OnDestroy,
  Renderer2,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { CustomTooltipComponent } from './custom-tooltip.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { takeUntil } from 'rxjs/operators';
import { DeviceInfoService } from '@core/services/device-info.service';
import { AllDeviceInfo } from '@interfaces';
import { ReplaySubject, fromEvent } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Directive({
  // eslint-disable-next-line
  selector: '[customTooltip]',
})
export class CustomTooltipDirective implements OnInit, OnDestroy {
  @Input() showToolTip = true;
  @Input('customTooltip') text: string;
  @Input() contentTemplate: TemplateRef<any>;

  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  private _overlayRef: OverlayRef;
  public deviceInfo: AllDeviceInfo;
  private isTooltipVisible: boolean = false;

  constructor(
    private _overlay: Overlay,
    private _overlayPositionBuilder: OverlayPositionBuilder,
    private _elementRef: ElementRef,
    private readonly deviceInfoService: DeviceInfoService,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  public ngOnInit(): void {
    this.deviceInfo = this.deviceInfoService.getInfo();

    this.deviceInfoService.infoEmitter.pipe(takeUntil(this.destroy$)).subscribe(info => {
      this.deviceInfo = info;
    });

    if (!this.showToolTip) {
      return;
    }

    const positionStrategy =
      this.deviceInfo.deviceTypeDetected !== 'PHONE'
        ? this._overlayPositionBuilder.flexibleConnectedTo(this._elementRef).withPositions([
            {
              originX: 'end',
              originY: 'bottom',
              overlayX: 'start',
              overlayY: 'top',
              offsetY: -200,
              offsetX: -50,
            },
          ])
        : this._overlayPositionBuilder.flexibleConnectedTo(this._elementRef).withPositions([
            {
              originX: 'center',
              originY: 'bottom',
              overlayX: 'center',
              overlayY: 'top',
            },
          ]);

    this._overlayRef = this._overlay.create({
      positionStrategy,
      panelClass: 'custom-overlay-tooltip',
      width: this.deviceInfo.deviceTypeDetected === 'PHONE' ? '80vw' : undefined,
      hasBackdrop: this.deviceInfo.deviceTypeDetected === 'PHONE',
      backdropClass: this.deviceInfo.deviceTypeDetected === 'PHONE' ? 'transparent-backdrop' : undefined,
    });

    if (isPlatformBrowser(this.platformId)) {
      fromEvent<MouseEvent>(document, 'click')
        .pipe(takeUntil(this.destroy$))
        .subscribe((event: MouseEvent) => {
          const target = event.target as Node;

          // Check if the click is outside the tooltip and its triggering element
          if (
            this.isTooltipVisible &&
            !this._elementRef.nativeElement.contains(target) &&
            !this._overlayRef.overlayElement.contains(target)
          ) {
            this.closeToolTip();
          }
        });
    }
  }

  @HostListener('mouseenter')
  public show(): void {
    if (!this.isTooltipVisible && this.deviceInfo.deviceTypeDetected === 'DESKTOP') {
      this.showTooltip();
    }
  }

  @HostListener('mouseleave')
  public hide(): void {
    if (this.isTooltipVisible && this.deviceInfo.deviceTypeDetected === 'DESKTOP') {
      this.closeToolTip();
    }
  }

  @HostListener('click')
  public toggleTooltip(): void {
    if (this.isTooltipVisible) {
      this.closeToolTip();
    } else {
      this.showTooltip();
    }
  }

  public ngOnDestroy(): void {
    this.closeToolTip();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private closeToolTip(): void {
    if (this._overlayRef) {
      this._overlayRef.detach();
      this.isTooltipVisible = false;
    }
  }

  private showTooltip(): void {
    if (this._overlayRef && !this._overlayRef.hasAttached()) {
      const tooltipRef: ComponentRef<CustomTooltipComponent> = this._overlayRef.attach(
        new ComponentPortal(CustomTooltipComponent)
      );
      tooltipRef.instance.text = this.text;
      tooltipRef.instance.contentTemplate = this.contentTemplate;
      this.isTooltipVisible = true;
    }
  }
}
