import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APP_DETAILS_TAB, AvailableLanguages, getAppDetailsTabNameByTabIndex } from '@constants';
import { AllDeviceInfo, InAppNotification, InAppNotificationType } from '@interfaces';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from '@core/services/analytics.service';
import { DeviceInfoService } from '@core/services/device-info.service';
import { ManagementFacade } from '@state/management';
import { InAppNotificationsFacade } from '@state/notifications';
import { RouterFacade } from '@state/router';
import { UserAuthFacade } from '@state/user-auth';
import { UserProcessFacade } from '@state/user-process';
import { debounce } from 'lodash-es';
import { Observable, ReplaySubject } from 'rxjs';
import { filter, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { ChatService } from '../chat/chat.service';
import { setNotificationViewOpts } from './notification.config';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  @ViewChild('notificationsMainWindow') notificationsMainWindow: ElementRef<HTMLElement>;
  @ViewChild('notificationsList') notificationsList: ElementRef<HTMLElement>;

  @Output() closeNotifications: EventEmitter<void> = new EventEmitter<void>();

  public loading$: Observable<boolean> = this.inAppNotifications.loading$;
  public loadingMore$: Observable<boolean> = this.inAppNotifications.loadingMore$;
  public shortlistPagination$ = this.inAppNotifications.shortlistPagination$;
  public allNotificationsLoaded = false;

  public loadingChangesIn = '';
  public shortlist: InAppNotification[];
  public InAppNotificationType = InAppNotificationType;
  public showOnlyUnread = false;
  public currentLang: AvailableLanguages;

  public deviceInfo: AllDeviceInfo;

  private boundClickListener = this.clickListener.bind(this);
  private debouncedOnscroll = debounce(({ target, srcElement }) => {
    const { clientHeight } = srcElement as HTMLElement;
    const { scrollTop, scrollHeight } = target as HTMLElement;

    const shouldLoadMore = scrollHeight - scrollTop < clientHeight + 100;
    if (shouldLoadMore) {
      this.loadShortlistMore();
    }
  }, 150);

  constructor(
    private readonly inAppNotifications: InAppNotificationsFacade,
    private readonly routerFacade: RouterFacade,
    private readonly userAuthFacade: UserAuthFacade,
    private readonly userProcessFacade: UserProcessFacade,
    private readonly router: Router,
    private readonly managementFacade: ManagementFacade,
    private readonly activatedRoute: ActivatedRoute,
    private readonly chatService: ChatService,
    private readonly translateService: TranslateService,
    private readonly deviceInfoService: DeviceInfoService,
    private readonly analyticsService: AnalyticsService
  ) {}

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    const { key, code } = event;
    if (key.toLowerCase() === 'escape' || code.toLowerCase() === 'escape') {
      this.closeNotifications.emit();
    }
  }

  ngOnInit(): void {
    this.deviceInfo = this.deviceInfoService.getInfo();

    this.deviceInfoService.infoEmitter.pipe(takeUntil(this.destroy$)).subscribe(info => {
      this.deviceInfo = info;
    });

    this.currentLang = this.translateService.currentLang as AvailableLanguages;
    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(({ lang }) => {
      this.currentLang = lang as AvailableLanguages;
    });

    this.loadingChangesIn = '';
    this.inAppNotifications.getShortlist(this.showOnlyUnread);
    this.inAppNotifications.shortlist$
      .pipe(
        filter(shortlist => !!shortlist?.length),
        withLatestFrom(this.userAuthFacade.myself$),
        takeUntil(this.destroy$)
      )
      .subscribe(([rawShortlist, myself]) => {
        this.shortlist = this.stackRepeatedNotifications(rawShortlist).map(notif =>
          setNotificationViewOpts(notif, myself, this.translateService)
        );
      });
    document.addEventListener('click', this.boundClickListener);
  }

  ngOnDestroy(): void {
    document.removeEventListener('click', this.boundClickListener);
    this.notificationsList?.nativeElement.removeAllListeners('scroll');
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    this.notificationsList?.nativeElement.addEventListener('scroll', this.debouncedOnscroll);
  }

  public async notificationClick(notification: InAppNotification, $event: MouseEvent): Promise<any> {
    $event.preventDefault();
    $event.stopPropagation();
    const { relatedForeignerId, relatedUserDocumentId, relatedUserProcessId } = notification;

    if (notification.read === false) {
      await this.markAsRead(notification);
    }

    const currPath = this.router.url.split('?')[0].slice(1);
    const queryParams = this.activatedRoute.snapshot.queryParams;
    let appDetailsTab = queryParams.appDetailsTab;
    const tabIndex = queryParams.tabIndex;
    if (tabIndex !== undefined) {
      appDetailsTab = getAppDetailsTabNameByTabIndex(Number(tabIndex));
    }
    if (!tabIndex && !appDetailsTab) {
      appDetailsTab = APP_DETAILS_TAB.GENERAL;
    }
    const isInGeneralView = appDetailsTab === APP_DETAILS_TAB.GENERAL;
    const isInDocsView = appDetailsTab === APP_DETAILS_TAB.DOCS;
    const isInSummaryView = appDetailsTab === APP_DETAILS_TAB.SUMMARY;
    const isInPreSurveyView = appDetailsTab === APP_DETAILS_TAB.PRE_SURVEY;

    switch (notification.type) {
      // Employee clicks on notification
      case InAppNotificationType.FOREIGNER_SENDS_APPLICATION_WITH_POST:
      case InAppNotificationType.FOREIGNER_SET_AVAILABILITY: {
        const linkParams = ['management', 'application-details', relatedForeignerId, relatedUserProcessId];
        const pathForNotification = linkParams.join('/');
        const shouldRefresh = currPath === pathForNotification && isInSummaryView;

        if (shouldRefresh) {
          this.managementFacade.getUserProcess({
            userId: relatedForeignerId,
            userProcessId: relatedUserProcessId,
          });
        }

        this.closeNotifications.emit();
        this.routerFacade.changeRoute({
          linkParams,
          extras: {
            queryParams: {
              appDetailsTab: APP_DETAILS_TAB.SUMMARY,
            },
          },
        });
        break;
      }
      // Employee clicks on notification
      case InAppNotificationType.FOREIGNER_SENT_PRE_SURVEY_FOR_VERIFICATION: {
        const linkParams = ['management', 'application-details', relatedForeignerId, relatedUserProcessId];
        const pathForNotification = linkParams.join('/');
        const shouldRefresh = currPath === pathForNotification;

        if (shouldRefresh) {
          this.managementFacade.getUser(relatedForeignerId);
          // we don't hav to call getUserProcess because its called when user enters pre-survey.tab view
          // in application-details
          // this.managementFacade.getUserProcess({ userId: relatedForeignerId, userProcessId: relatedUserProcessId });
        }

        this.closeNotifications.emit();
        this.routerFacade.changeRoute({
          linkParams,
          extras: { queryParams: { appDetailsTab: APP_DETAILS_TAB.PRE_SURVEY } },
        });
        break;
      }
      // Employee clicks on notification
      case InAppNotificationType.FOREIGNER_SENT_APPLICATION_TO_VERIFICATION: {
        const linkParams = ['management', 'application-details', relatedForeignerId, relatedUserProcessId];
        const pathForNotification = linkParams.join('/');
        const shouldRefresh = currPath === pathForNotification && isInGeneralView;

        if (shouldRefresh) {
          this.managementFacade.getUser(relatedForeignerId);
          this.managementFacade.getUserProcess({ userId: relatedForeignerId, userProcessId: relatedUserProcessId });
        }
        this.closeNotifications.emit();
        this.routerFacade.changeRoute({
          linkParams,
          extras: { queryParams: { appDetailsTab: APP_DETAILS_TAB.GENERAL } },
        });
        break;
      }
      // Employee clicks on notification
      case InAppNotificationType.FOREIGNER_ADDED_DOCUMENT_COMMENT: {
        const linkParams = ['management', 'application-details', relatedForeignerId, relatedUserProcessId];
        const pathForNotification = linkParams.join('/');
        const shouldRefresh = currPath === pathForNotification && isInDocsView;

        if (shouldRefresh) {
          this.managementFacade.getUserProcessDocuments({
            userId: relatedForeignerId,
            userProcessId: relatedUserProcessId,
          });
        }
        this.closeNotifications.emit();
        this.routerFacade.changeRoute({
          linkParams,
          extras: {
            queryParams: {
              appDetailsTab: APP_DETAILS_TAB.DOCS,
              revealUserDocumentId: relatedUserDocumentId,
              openChatForUserDocumentId: relatedUserDocumentId,
            },
          },
        });

        break;
      }
      // Employee clicks on notification
      case InAppNotificationType.INPOL_SENDING_ERROR:
      case InAppNotificationType.INPOL_SENDING_SUCCESS:
      case InAppNotificationType.MOS_SENDING_ERROR:
      case InAppNotificationType.MOS_SENDING_SUCCESS:
      case InAppNotificationType.DOCUMENTS_GENERATION_ERROR:
      case InAppNotificationType.DOCUMENTS_GENERATION_SUCCESS:
      case InAppNotificationType.FOREIGNER_APPLICATION_MODE_HAS_CHANGED:
      case InAppNotificationType.FOREIGNER_PICKED_SIGNING_MODE:
      case InAppNotificationType.FOREIGNER_CHANGED_PLANNED_DOCUMENTS_SIGNING_MODE:
      case InAppNotificationType.FOREIGNER_CHANGED_PLANNED_DOCUMENTS_SIGNING_DATE:
      case InAppNotificationType.DOCUMENTS_SENT_TO_EXTERNAL_SYSTEM_AND_GENERATED:
      case InAppNotificationType.FOREIGNER_CONFIRMED_READINESS_FOR_VISIT:
      case InAppNotificationType.FOREIGNER_CANCELLED_READINESS_FOR_VISIT: {
        const linkParams = ['management', 'application-details', relatedForeignerId, relatedUserProcessId];
        const pathForNotification = linkParams.join('/');
        const shouldRefresh = currPath === pathForNotification && isInSummaryView;

        if (shouldRefresh) {
          this.managementFacade.getUserProcess({
            userId: relatedForeignerId,
            userProcessId: relatedUserProcessId,
          });
        }

        this.closeNotifications.emit();
        this.routerFacade.changeRoute({
          linkParams,
          extras: { queryParams: { appDetailsTab: APP_DETAILS_TAB.SUMMARY } },
        });
        break;
      }
      case InAppNotificationType.FOREIGNER_ADDED_ITEMS_TO_REVIEW: {
        const linkParams = ['management', 'application-details', relatedForeignerId, relatedUserProcessId];
        const pathForNotification = linkParams.join('/');
        const shouldRefresh = currPath === pathForNotification && isInSummaryView;

        if (shouldRefresh) {
          this.managementFacade.getUserProcess({
            userId: relatedForeignerId,
            userProcessId: relatedUserProcessId,
          });
        }

        this.closeNotifications.emit();
        this.routerFacade.changeRoute({
          linkParams,
          extras: {
            queryParams: {
              appDetailsTab: APP_DETAILS_TAB.SUMMARY,
              showReviewItems: true,
            },
          },
        });
        break;
      }

      // Employee clicks on notification
      case InAppNotificationType.FOREIGNER_HAS_BEEN_ASSIGNED_TO_YOU: {
        this.closeNotifications.emit();
        const linkParams = ['management', 'foreigner-details', relatedForeignerId];
        this.routerFacade.changeRoute({
          linkParams,
          extras: { queryParams: { tabIndex: 0 } },
        });
        break;
      }
      // FOREIGNER clicks on notification
      case InAppNotificationType.EMPLOYEE_ADDED_DOCUMENT_COMMENT: {
        const linkParams = ['user-process', relatedUserProcessId, 'documents'];
        this.closeNotifications.emit();
        this.routerFacade.changeRoute({
          linkParams,
          extras: {
            queryParams: {
              revealDocumentId: relatedUserDocumentId,
              openChatForUserDocumentId: relatedUserDocumentId,
            },
          },
        });
        break;
      }
      // FOREIGNER clicks on notification
      // case InAppNotificationType.EMPLOYEE_CHANGED_DOCUMENT_STATUS: {
      //   const linkParams = ['user-process', relatedUserProcessId, 'documents'];
      //   const shouldRefresh = this.router.url.includes(linkParams.join('/'));
      //   this.closeNotifications.emit();

      //   if (shouldRefresh) {
      //     this.userProcessFacade.getListOfDocuments(relatedUserProcessId);
      //   }

      //   this.routerFacade.changeRoute({
      //     linkParams,
      //     extras: { queryParams: { revealDocumentId: relatedUserDocumentId } },
      //   });

      //   break;
      // }
      // FOREIGNER clicks on notification
      case InAppNotificationType.EMPLOYEE_CANCELLED_OFFICE_APPOINTMENT:
      case InAppNotificationType.APPLICATION_MODE_CHANGED:
      case InAppNotificationType.APPLICATION_STATUS_CHANGED: {
        this.closeNotifications.emit();
        const linkParams = ['user-process', relatedUserProcessId];
        const shouldRefresh = this.router.url.includes(linkParams.join('/'));
        this.closeNotifications.emit();

        if (shouldRefresh) {
          const l = window.location.href.split('/');
          l.pop();
          window.location.href = l.join('/');
        } else {
          this.routerFacade.changeRoute({
            linkParams,
            extras: { queryParams: {} },
          });
        }

        break;
      }
      case InAppNotificationType.APPLICATION_STATUS_CHANGED_WITH_TYPE: {
        this.closeNotifications.emit();
        const linkParams = ['user-process', relatedUserProcessId];
        const shouldRefresh = this.router.url.includes(linkParams.join('/'));
        this.closeNotifications.emit();

        if (shouldRefresh) {
          const l = window.location.href.split('/');
          l.pop();
          window.location.href = l.join('/');
        } else {
          this.routerFacade.changeRoute({
            linkParams,
            extras: { queryParams: {} },
          });
        }

        break;
      }
      // FOREIGNER clicks on notification
      case InAppNotificationType.NEW_FINAL_VERIFICATION_COMMENTS:
      case InAppNotificationType.CHANGED_FINAL_VERIFICATION_COMMENTS: {
        this.closeNotifications.emit();
        const linkParams = ['user-process', relatedUserProcessId, 'summary'];
        const shouldRefresh = this.router.url.includes(linkParams.join('/'));
        this.closeNotifications.emit();

        if (shouldRefresh) {
          const l = window.location.href.split('/');
          l.pop();
          window.location.href = `${l.join('/')}/summary?showDocumentsVerificationComment=true`;
        } else {
          this.routerFacade.changeRoute({
            linkParams,
            extras: {
              queryParams: { showDocumentsVerificationComment: true },
            },
          });
        }

        break;
      }
      // FOREIGNER clicks on notification
      case InAppNotificationType.DOCUMENTS_LIST_CHANGED: {
        this.closeNotifications.emit();
        const linkParams = ['user-process', relatedUserProcessId];
        const shouldRefresh = this.router.url.includes(linkParams.join('/'));
        this.closeNotifications.emit();

        if (shouldRefresh) {
          this.userProcessFacade.getMyProcessDetails(relatedUserProcessId);
          this.userProcessFacade.getListOfDocuments(relatedUserProcessId);
        } else {
          this.routerFacade.changeRoute({
            linkParams,
            extras: { queryParams: {} },
          });
        }
        break;
      }
      // !---------------------------------- DEPRECATED
      // EMPLOYEE clicks on notification
      // case InAppNotificationType.FOREIGNER_SENT_MESSAGE_IN_CHAT: {
      //   const linkParams = ['management', 'foreigner-details', relatedForeignerId];
      //   const inForeignerDetailsOrForeignerApplication = currPath.includes(relatedForeignerId);

      //   this.closeNotifications.emit();

      //   if (!inForeignerDetailsOrForeignerApplication) {
      //     this.chatFacade.getChatMessages(relatedForeignerId);
      //     this.routerFacade.changeRoute({ linkParams, extras: { queryParams: { tabIndex: 1 } } });
      //   }

      //   this.chatService.showChat$.next({ show: true, relatedForeignerId });

      //   break;
      // }
      // FOREIGNER clicks on notification
      case InAppNotificationType.EMPLOYEE_SENT_MESSAGE_IN_CHAT: {
        this.closeNotifications.emit();
        if (this.deviceInfo.deviceTypeDetected === 'DESKTOP') {
          this.chatService.showChat$.next({ show: true, relatedForeignerId });
        } else {
          await this.router.navigate(['/chat']);
        }
        break;
      }
      // EMPLOYEE clicks on notification
      case InAppNotificationType.FOREIGNER_CHANGED_PERSONAL_DETAILS: {
        const linkParams = ['management', 'application-details', relatedForeignerId, relatedUserProcessId];
        const pathForNotification = linkParams.join('/');
        const shouldRefresh = currPath === pathForNotification;

        if (shouldRefresh) {
          this.managementFacade.getUser(relatedForeignerId);
          this.managementFacade.getUserProcess({ userId: relatedForeignerId, userProcessId: relatedUserProcessId });
        }

        this.closeNotifications.emit();
        this.routerFacade.changeRoute({
          linkParams,
          extras: { queryParams: { appDetailsTab: APP_DETAILS_TAB.GENERAL } },
        });

        break;
      }
      // EMPLOYEE clicks on notification
      case InAppNotificationType.FOREIGNER_PLANNING_TO_COME_TO_THE_OFFICE: {
        const linkParams = ['management', 'visits-calendar'];
        const queryParams = { visibleDate: notification.data?.additionalInfo?.newDate || '' };
        this.closeNotifications.emit();
        this.routerFacade.changeRoute({ linkParams, extras: { queryParams } });
        break;
      }
      case InAppNotificationType.FOREIGNER_PLANNING_TO_COME_TO_OTHER_OFFICE: {
        const linkParams = ['management', 'visits-calendar'];
        const queryParams = { visibleDate: notification.data?.additionalInfo?.newDate || '' };
        this.closeNotifications.emit();
        this.routerFacade.changeRoute({ linkParams, extras: { queryParams } });
        break;
      }
      // EMPLOYEE clicks on notification
      case InAppNotificationType.FOREIGNER_CHANGED_DATE_OF_COMPANY_OFFICE_VISIT: {
        const linkParams = ['management', 'visits-calendar'];
        const queryParams = { visibleDate: notification.data?.additionalInfo?.newDate || '' };
        this.closeNotifications.emit();
        this.routerFacade.changeRoute({ linkParams, extras: { queryParams } });
        break;
      }
      // EMPLOYEE clicks on notification
      case InAppNotificationType.FOREIGNER_CHANGED_DATE_OF_OTHER_COMPANY_OFFICE_VISIT: {
        const linkParams = ['management', 'visits-calendar'];
        const queryParams = { visibleDate: notification.data?.additionalInfo?.newDate || '' };
        this.closeNotifications.emit();
        this.routerFacade.changeRoute({ linkParams, extras: { queryParams } });
        break;
      }
      // EMPLOYEE clicks on notification
      case InAppNotificationType.FOREIGNER_SET_IMMIGRATION_OFFICE_VISIT_DATE: {
        const linkParams = ['management', 'visits-calendar'];
        const queryParams = { visibleDate: notification.data?.additionalInfo?.visitDate || '' };
        this.closeNotifications.emit();
        this.routerFacade.changeRoute({ linkParams, extras: { queryParams } });
        break;
      }
      case InAppNotificationType.FOREIGNER_CHANGED_IMMIGRATION_OFFICE_VISIT_DATE: {
        const linkParams = ['management', 'visits-calendar'];
        const queryParams = { visibleDate: notification.data?.additionalInfo?.visitDate || '' };
        this.closeNotifications.emit();
        this.routerFacade.changeRoute({ linkParams, extras: { queryParams } });
        break;
      }
      // EMPLOYEE clicks on notification
      case InAppNotificationType.FOREIGNER_CANCELLED_VISIT_IN_COMPANY_OFFICE: {
        /// --- do nothing
        // WE HAVE TO THINK ABOUT CONSULTANTS ALSO
        // as they dont have access to the application-summary
        // const linkParams = ['management', 'visits-calendar'];
        // const queryParams = { visibleDate: notification.data?.additionalInfo?.newDate || '' };
        this.closeNotifications.emit();
        // this.routerFacade.changeRoute({ linkParams, extras: { queryParams } });
        break;
      }
      // EMPLOYEE clicks on notification
      case InAppNotificationType.FOREIGNER_CANCELLED_VISIT_IN_OTHER_COMPANY_OFFICE: {
        /// --- do nothing
        // WE HAVE TO THINK ABOUT CONSULTANTS ALSO
        // as they dont have access to the application-summary
        // const linkParams = ['management', 'visits-calendar'];
        // const queryParams = { visibleDate: notification.data?.additionalInfo?.newDate || '' };
        this.closeNotifications.emit();
        // this.routerFacade.changeRoute({ linkParams, extras: { queryParams } });
        break;
      }
      // EMPLOYEE clicks on notification

      case InAppNotificationType.REASSIGNED_FOREIGNERS_TO_YOU: {
        const linkParams = ['management', 'applications-list'];
        const queryParams = { creatorId: notification.recipientId };
        this.closeNotifications.emit();
        this.routerFacade.changeRoute({ linkParams, extras: { queryParams } });
        break;
      }
      case InAppNotificationType.NEW_APPLICATION_HAS_BEEN_ADDED: {
        this.closeNotifications.emit();
        window.location.href = '/user-process/' + relatedUserProcessId;
        break;
      }
      case InAppNotificationType.NEW_APPLICATION_HAS_BEEN_ADDED_WITH_TYPE: {
        this.closeNotifications.emit();
        window.location.href = '/user-process/' + relatedUserProcessId;
        break;
      }
      case InAppNotificationType.APPLICATION_HAS_BEEN_DELETED: {
        this.closeNotifications.emit();
        break;
      }
      case InAppNotificationType.PARTNER_REQUESTED_PAYOUT: {
        this.closeNotifications.emit();
        const linkParams = ['partners', 'payouts'];
        const pathForNotification = linkParams.join('/');
        const shouldRefresh = currPath === pathForNotification;

        if (shouldRefresh) {
          window.location.href = '/partners/payouts';
        } else {
          this.routerFacade.changeRoute({ linkParams });
        }
        break;
      }
      default:
        console.error('NO_CALLBACK_FOR_NOTIFICATION_TYPE');
        return;
    }
    this.analyticsService.trackEvent('user_event', 'user_clicked_notification', { type: notification.type });
  }

  public toggleShowOnlyUnread(): void {
    this.showOnlyUnread = !this.showOnlyUnread;
    this.shortlist = [];
    this.inAppNotifications.getShortlist(this.showOnlyUnread);
  }

  public markAsRead(notification: InAppNotification): Promise<any> {
    this.loadingChangesIn = notification.id;
    const promiseToResolve = new Promise(resolve => {
      this.inAppNotifications.markAsReadSuccess$.pipe(take(1), takeUntil(this.destroy$)).subscribe(() => {
        setTimeout(() => {
          this.loadingChangesIn = '';
          return resolve(true);
        });
      });
    });

    const ids = notification.stackedIds?.length ? [...notification.stackedIds] : [notification.id];
    this.inAppNotifications.markAsRead(ids);
    return promiseToResolve;
  }

  public markAsUnread(notification: InAppNotification): void {
    this.loadingChangesIn = notification.id;

    this.inAppNotifications.markAsUnreadSuccess$.pipe(take(1), takeUntil(this.destroy$)).subscribe(() => {
      this.loadingChangesIn = '';
    });

    const ids = notification.stackedIds?.length ? [...notification.stackedIds] : [notification.id];
    this.inAppNotifications.markAsUnRead(ids);
  }

  public markAllAsRead(): void {
    this.inAppNotifications.markAllAsRead();
  }

  public loadShortlistMore(): void {
    this.shortlistPagination$.pipe(take(1), takeUntil(this.destroy$)).subscribe(pagination => {
      // we've loaded all of the notifications
      if (pagination.count >= pagination.all) {
        this.allNotificationsLoaded = true;
        return;
      }

      this.allNotificationsLoaded = false;
      this.inAppNotifications.getShortlistMore({
        ...pagination,
        offset: pagination.offset + pagination.limit,
      });
    });
  }

  public trackByFn(index: number, notification: InAppNotification): string {
    return notification.id;
  }

  private clickListener($event: MouseEvent): void {
    // if click has been inside window - do nothing
    if (this.notificationsMainWindow.nativeElement.contains($event.target as any)) {
      return;
    }

    const htmlTarget = $event.target as HTMLElement;
    if (
      htmlTarget.classList.contains('open-notifications-button') ||
      htmlTarget.classList.contains('open-notifications-icon')
    ) {
      return;
    }

    this.closeNotifications.emit();
  }

  private stackRepeatedNotifications(notifications: InAppNotification[]): InAppNotification[] {
    const stacked: InAppNotification[] = [];

    const NO_STACK_TYPES = [
      InAppNotificationType.FOREIGNER_CHANGED_PLANNED_DOCUMENTS_SIGNING_MODE,
      InAppNotificationType.FOREIGNER_CHANGED_PLANNED_DOCUMENTS_SIGNING_DATE,
      InAppNotificationType.FOREIGNER_CHANGED_DATE_OF_COMPANY_OFFICE_VISIT,
      InAppNotificationType.FOREIGNER_CHANGED_DATE_OF_OTHER_COMPANY_OFFICE_VISIT,
      InAppNotificationType.FOREIGNER_CANCELLED_VISIT_IN_COMPANY_OFFICE,
      InAppNotificationType.FOREIGNER_CANCELLED_VISIT_IN_OTHER_COMPANY_OFFICE,
      InAppNotificationType.REASSIGNED_FOREIGNERS_TO_YOU,
      InAppNotificationType.NEW_APPLICATION_HAS_BEEN_ADDED,
      InAppNotificationType.NEW_APPLICATION_HAS_BEEN_ADDED_WITH_TYPE,
      InAppNotificationType.APPLICATION_HAS_BEEN_DELETED,
      InAppNotificationType.FOREIGNER_SET_IMMIGRATION_OFFICE_VISIT_DATE,
      InAppNotificationType.FOREIGNER_CHANGED_IMMIGRATION_OFFICE_VISIT_DATE,
      InAppNotificationType.PARTNER_REQUESTED_PAYOUT,
    ];

    notifications.forEach((notification, currIndex) => {
      if (currIndex === 0) {
        stacked.push(notification);
        return;
      }

      const prevNotif = stacked[stacked.length - 1];

      if (
        NO_STACK_TYPES.includes(notification.type) === false &&
        prevNotif.type === notification.type &&
        prevNotif.relatedForeignerId === notification.relatedForeignerId &&
        prevNotif.relatedUserProcessId === notification.relatedUserProcessId &&
        prevNotif.relatedUserDocumentId === notification.relatedUserDocumentId &&
        prevNotif.read === notification.read
      ) {
        const stackedNotif: InAppNotification = {
          ...notification,
          stacked: true,
          stackSize: prevNotif.stackSize ? prevNotif.stackSize + 1 : 2,
          stackedIds: prevNotif.stackedIds
            ? [notification.id, ...prevNotif.stackedIds]
            : [notification.id, prevNotif.id],
        };
        stacked[stacked.length - 1] = stackedNotif;
        return;
      }

      stacked.push(notification);
    });

    return stacked;
  }
}
