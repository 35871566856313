import { Action, createReducer, on } from '@ngrx/store';
import * as dcActions from './partner-codes.actions';
import { initialState, PartnerCodesState } from './partner-codes.state';

const reducer = createReducer(
  initialState,

  on(
    dcActions.generatePartnerCodeForUser,
    dcActions.editPartnerCode,
    dcActions.getPartnerCodeDetails,
    dcActions.getPartnerPointsSum,
    dcActions.getPartnerCodeUsage,
    dcActions.changePayoutStatus,
    dcActions.cancelPayout,
    dcActions.getPartnerPayoutsHistory,
    dcActions.getAllPartnerPoints,
    dcActions.changePointsStatus,
    dcActions.addPartnerPointsManually,
    dcActions.removePartnerPoints,
    dcActions.getGlobalTerms,
    dcActions.updateGlobalTerms,
    state => ({
      ...state,
      loading: true,
    })
  ),
  on(dcActions.searchPartners, dcActions.searchPayouts, state => ({
    ...state,
    loadingList: true,
  })),

  on(dcActions.searchPartnersSuccess, (state, { partners }) => ({
    ...state,
    loadingList: false,
    partners: partners.data,
    partnersPagination: partners.pagination,
  })),

  on(dcActions.searchPayoutsSuccess, (state, { payouts }) => ({
    ...state,
    loadingList: false,
    payouts: payouts.data,
    payoutsPagination: payouts.pagination,
  })),

  on(dcActions.regeneratePartnerCodeForUser, dcActions.setDifferentCodeForPartner, state => ({
    ...state,
    codeGenerationInProgress: true,
  })),
  on(
    dcActions.regeneratePartnerCodeForUserSuccess,
    dcActions.setDifferentCodeForPartnerSuccess,
    (state, { discountCode }) => ({
      ...state,
      codeGenerationInProgress: false,
      partnerCodeDetails: discountCode,
    })
  ),
  on(dcActions.regeneratePartnerCodeForUserError, dcActions.setDifferentCodeForPartnerError, state => ({
    ...state,
    codeGenerationInProgress: false,
  })),

  on(dcActions.getPartnerCodeDetailsSuccess, (state, { discountCode }) => ({
    ...state,
    loading: false,
    partnerCodeDetails: discountCode,
  })),

  on(dcActions.generatePartnerCodeForUserSuccess, dcActions.editPartnerCodeSuccess, (state, { discountCode }) => ({
    ...state,
    loading: false,
    partnerCodeDetails: discountCode,
  })),

  on(dcActions.getPartnerPointsSumSuccess, (state, { sumOfPoints }) => ({
    ...state,
    loading: false,
    sumOfPartnerPoints: sumOfPoints,
  })),
  on(dcActions.getPartnerCodeUsageSuccess, (state, { purchases }) => ({
    ...state,
    loading: false,
    partnerCodeUsage: purchases,
  })),

  on(dcActions.getPartnerPayoutsHistorySuccess, (state, { partnerPayouts }) => ({
    ...state,
    loading: false,
    partnerPayouts,
  })),

  on(dcActions.changePayoutStatusSuccess, (state, { partnerPayout }) => ({
    ...state,
    loading: false,
    partnerPayouts: state.partnerPayouts?.map(payout =>
      payout.id === partnerPayout.id ? { ...payout, ...partnerPayout } : payout
    ),
    payouts: state.payouts?.map(payout => (payout.id === partnerPayout.id ? { ...payout, ...partnerPayout } : payout)),
  })),
  on(dcActions.cancelPayoutSuccess, (state, { payoutId }) => ({
    ...state,
    loading: false,
    partnerPayouts: state.partnerPayouts?.filter(payout => payout.id !== payoutId),
    payouts: state.payouts?.filter(payout => payout.id !== payoutId),
  })),
  on(dcActions.getAllPartnerPointsSuccess, (state, { partnerPoints }) => ({
    ...state,
    loading: false,
    allPartnerPoints: partnerPoints,
  })),

  on(dcActions.changePointsStatusSuccess, (state, { partnerPoints }) => ({
    ...state,
    loading: false,
    allPartnerPoints: state.allPartnerPoints.map(points =>
      points.id === partnerPoints.id ? { ...points, ...partnerPoints } : points
    ),
  })),
  on(dcActions.addPartnerPointsManuallySuccess, (state, { partnerPoints }) => ({
    ...state,
    loading: false,
    allPartnerPoints: [{ ...partnerPoints }, ...(state.allPartnerPoints || [])],
  })),
  on(dcActions.removePartnerPointsSuccess, (state, { removedPointsId }) => ({
    ...state,
    loading: false,
    allPartnerPoints: state.allPartnerPoints.filter(points => points.id !== removedPointsId),
  })),

  on(dcActions.getGlobalTermsSuccess, dcActions.updateGlobalTermsSuccess, (state, { globalTerms }) => ({
    ...state,
    globalTerms,
    loading: false,
  })),

  on(
    dcActions.getPartnerPayoutsHistoryError,
    dcActions.editPartnerCodeError,
    dcActions.generatePartnerCodeForUserError,
    dcActions.getPartnerCodeDetailsError,
    dcActions.getPartnerPointsSumError,
    dcActions.getPartnerCodeUsageError,
    dcActions.getAllPartnerPointsError,
    dcActions.changePointsStatusError,
    dcActions.addPartnerPointsManuallyError,
    dcActions.removePartnerPointsError,
    dcActions.getGlobalTermsError,
    dcActions.updateGlobalTermsError,
    state => ({
      ...state,
      loading: false,
    })
  ),
  on(dcActions.searchPartnersError, dcActions.searchPayoutsError, state => ({
    ...state,
    loadingList: false,
  }))
);

export function partnerCodesReducer(state: PartnerCodesState | undefined, action: Action): PartnerCodesState {
  return reducer(state, action);
}
