import { Component, OnDestroy, OnInit } from '@angular/core';
import { SupportChatFacade } from '@state/support-chat';
import { ReplaySubject } from 'rxjs';
import { DeviceInfoService } from '@core/services/device-info.service';
import { AllDeviceInfo } from '@interfaces';
import { filter, takeUntil } from 'rxjs/operators';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-chats-wrapper-mobile',
  templateUrl: './chats-wrapper-mobile.component.html',
  styleUrls: ['./chats-wrapper-mobile.component.scss'],
})
export class ChatsWrapperMobileComponent implements OnInit, OnDestroy {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  public showChatsPanel = false;
  public showChatsPanelWindow = false;

  public openSupportChat$ = this.supportChatFacade.openSupportChat$;
  public unreadCount$ = this.supportChatFacade.myUnreadCount$;

  public deviceInfo: AllDeviceInfo;

  constructor(
    private readonly supportChatFacade: SupportChatFacade,
    private readonly deviceInfoService: DeviceInfoService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.deviceInfo = this.deviceInfoService.getInfo();

    this.deviceInfoService.infoEmitter.pipe(takeUntil(this.destroy$)).subscribe(info => {
      this.deviceInfo = info;
    });

    this.supportChatFacade.getMyUnreadCount();

    // Route change subscription
    this.startListeningToRouteChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public toggleChat(value?: boolean): void {
    if (value !== undefined) {
      this.showChatsPanel = value;
    } else {
      this.showChatsPanel = !this.showChatsPanel;
    }
  }

  public toggleChatWindow(value?: boolean): void {
    if (value !== undefined) {
      this.showChatsPanelWindow = value;
    } else {
      this.showChatsPanelWindow = !this.showChatsPanelWindow;
    }
  }

  public startListeningToRouteChanges() {
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationStart),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.toggleChat(false);
      });
  }
}
