import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { DeviceInfoService } from '@core/services/device-info.service';
import { ConfirmationModalComponent } from './confirmation-modal.component';

export interface ModalOpts {
  confirmationTranslated?: string;
  confirmationToTranslate?: string;
  translateParams?: { [key: string]: any };
  yesButtonKey?: string;
  noButtonKey?: string;
  additionalMessage?: boolean;
  initialValue?: any;
  autofocus?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ConfirmationModalService {
  constructor(
    private dialog: MatDialog,
    private readonly deviceInfoService: DeviceInfoService
  ) {}

  public open(opts: ModalOpts): MatDialogRef<ConfirmationModalComponent> {
    const { deviceTypeDetected } = this.deviceInfoService.getInfo();
    let dialogWidth = '500px';

    if (deviceTypeDetected !== 'DESKTOP') {
      dialogWidth = '95vw';
    }

    return this.dialog.open(ConfirmationModalComponent, {
      width: dialogWidth,
      maxWidth: dialogWidth,
      panelClass: ['confirmation-modal'],
      data: { ...opts },
      autoFocus: opts.autofocus || false,
    });
  }
}
