import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { UserAssetsHttpService } from '@core/http/user-assets.http.service';
import * as actions from './user-files.actions';
import { GetFileService } from '@core/services/get-file.service';

@Injectable()
export class UserFilesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly nzMessage: NzMessageService,
    private readonly translateService: TranslateService,
    private readonly userAssetsService: UserAssetsHttpService,
    private readonly getFileService: GetFileService
  ) {}

  getFilesOfUserDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getFilesOfUserDocuments),
      switchMap(({ opts }) => {
        return this.userAssetsService.getUserFilesOfDocuments(opts).pipe(
          map(filesOfDocuments => {
            return actions.getFilesOfUserDocumentsSuccess({ filesOfDocuments, getOpts: opts });
          }),
          catchError(() => {
            this.nzMessage.error(this.translateService.instant('NT3.ERROR_DOWNLOADING_FILES_LIST'));
            return of(actions.getFilesOfUserDocumentsError());
          })
        );
      })
    )
  );

  getUserAssets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getUserAssets),
      switchMap(({ opts }) => {
        return this.userAssetsService.getUserAssets(opts).pipe(
          map(userAssets => {
            return actions.getUserAssetsSuccess({ userAssets, getOpts: opts });
          }),
          catchError(() => {
            this.nzMessage.error(this.translateService.instant('NT3.ERROR_DOWNLOADING_FILES_LIST'));
            return of(actions.getUserAssetsError());
          })
        );
      })
    )
  );

  downloadUserAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.downloadAsset),
      switchMap(({ userAssetId }) => {
        return this.userAssetsService.downloadAnyAsset({ userAssetId }).pipe(
          map(response => {
            if (this.getFileService.getFile(response)) {
              return actions.downloadAssetSuccess();
            } else {
              return actions.downloadAssetError();
            }
          }),
          catchError(() => {
            this.nzMessage.error(this.translateService.instant('NT3.ERROR_DOWNLOADING_ATTACHMENT'));
            return of(actions.downloadAssetError());
          })
        );
      })
    )
  );
}
