import {
  AddPartnerPointsOpts,
  CancelPayoutOpts,
  ChangePayoutStatusOpts,
  ChangePointsStatusOpts,
  DiscountCode,
  EditPartnerCodeOpts,
  PaginatedResponse,
  PartnerGlobalTerms,
  PartnerPoints,
  PartnerPointsPayout,
  Purchase,
  RemovePartnerPointsOpts,
  SearchPartnersOpts,
  SearchPayoutsOpts,
  SumOfPartnerPoints,
  UpdateGlobalTermsOpts,
  User,
} from '@interfaces';
import { createAction, props } from '@ngrx/store';

export const searchPartners = createAction('[Partners] Search Partners', props<{ opts: SearchPartnersOpts }>());
export const searchPartnersSuccess = createAction(
  '[Partners] Search Partners SUCCESS',
  props<{ partners: PaginatedResponse<Partial<User>> }>()
);
export const searchPartnersError = createAction('[Partners] Search Partners ERROR');

export const searchPayouts = createAction('[Partners] Search Partners Payouts', props<{ opts: SearchPayoutsOpts }>());
export const searchPayoutsSuccess = createAction(
  '[Partners] Search Payouts SUCCESS',
  props<{ payouts: PaginatedResponse<PartnerPointsPayout> }>()
);
export const searchPayoutsError = createAction('[Partners] Search Payouts ERROR');

export const getPartnerCodeDetails = createAction(
  '[Partner Code] Get Partner Code Details',
  props<{ userId: string }>()
);
export const getPartnerCodeDetailsSuccess = createAction(
  '[Partner Code] Get Partner Code Details Success',
  props<{ userId: string; discountCode: DiscountCode }>()
);
export const getPartnerCodeDetailsError = createAction('[Partner Codes] Get Partner Code Details Error');

export const generatePartnerCodeForUser = createAction(
  '[Partner Code] Generate partner code for user',
  props<{ userId: string }>()
);
export const generatePartnerCodeForUserSuccess = createAction(
  '[Partner Code] Generate partner code for user Success',
  props<{ discountCode: DiscountCode }>()
);
export const generatePartnerCodeForUserError = createAction('[Partner Code] Generate partner code for user Error');

export const editPartnerCode = createAction('[Partner Code] Edit Partner Code', props<{ opts: EditPartnerCodeOpts }>());
export const editPartnerCodeSuccess = createAction(
  '[Partner Code] Edit Partner Code Success',
  props<{ discountCode: DiscountCode }>()
);
export const editPartnerCodeError = createAction('[Partner Code] Edit Partner Code Error');

export const regeneratePartnerCodeForUser = createAction(
  '[Partner Code] ReGenerate partner code for user',
  props<{ userId: string }>()
);
export const regeneratePartnerCodeForUserSuccess = createAction(
  '[Partner Code] ReGenerate partner code for user Success',
  props<{ discountCode: DiscountCode }>()
);
export const regeneratePartnerCodeForUserError = createAction('[Partner Code] ReqGenerate partner code for user Error');

export const setDifferentCodeForPartner = createAction(
  '[Partner Code] Set Different partner code for user',
  props<{ userId: string; code: string }>()
);
export const setDifferentCodeForPartnerSuccess = createAction(
  '[Partner Code] Set Different partner code for user SUCCESS',
  props<{ discountCode: DiscountCode }>()
);
export const setDifferentCodeForPartnerError = createAction('[Partner Code] Set Different partner code for user ERROR');

export const getPartnerPointsSum = createAction('[Partner Code] Get Partner Points Sum', props<{ userId: string }>());
export const getPartnerPointsSumSuccess = createAction(
  '[Partner Code] Get Partner Points Sum Success',
  props<{ userId: string; sumOfPoints: SumOfPartnerPoints }>()
);
export const getPartnerPointsSumError = createAction('[Partner Code] Get Partner Points Sum Error');

export const getPartnerCodeUsage = createAction('[Partner Code] Get Partner Code Usage', props<{ userId: string }>());
export const getPartnerCodeUsageSuccess = createAction(
  '[Partner Code] Get Partner Code Usage Success',
  props<{ userId: string; purchases: Partial<Purchase[]> }>()
);
export const getPartnerCodeUsageError = createAction('[Partner Code] Get Partner Code Usage Error');

export const getPartnerPayoutsHistory = createAction(
  '[Partner Code] Get Partner Payouts History',
  props<{ userId: string }>()
);
export const getPartnerPayoutsHistorySuccess = createAction(
  '[Partner Code] Get Partner Payouts History SUCCESS',
  props<{ userId: string; partnerPayouts: PartnerPointsPayout[] }>()
);
export const getPartnerPayoutsHistoryError = createAction('[Partner Code] Get Partner Payouts History ERROR');

export const changePayoutStatus = createAction(
  '[Partner Code] Change Partner Payout Status',
  props<{ opts: ChangePayoutStatusOpts }>()
);
export const changePayoutStatusSuccess = createAction(
  '[Partner Code] Change Partner Payout Status SUCCESS',
  props<{ partnerPayout: PartnerPointsPayout }>()
);
export const changePayoutStatusError = createAction('[Partner Code] Change Partner Payout Status ERROR');

export const cancelPayout = createAction('[Partner Code] Cancel Partner Payout', props<{ opts: CancelPayoutOpts }>());
export const cancelPayoutSuccess = createAction(
  '[Partner Code] Cancel Partner Payout SUCCESS',
  props<{ payoutId: string; success: boolean }>()
);
export const cancelPayoutError = createAction('[Partner Code] Cancel Partner Payout ERROR');

export const getAllPartnerPoints = createAction('[Partner Code] Get All Partner Points', props<{ userId: string }>());
export const getAllPartnerPointsSuccess = createAction(
  '[Partner Code] Get All Partner Points SUCCESS',
  props<{ partnerPoints: PartnerPoints[] }>()
);
export const getAllPartnerPointsError = createAction('[Partner Code] Get All Partner Points ERROR');

export const changePointsStatus = createAction(
  '[Partner Code] Change Partner Points Status',
  props<{ opts: ChangePointsStatusOpts }>()
);
export const changePointsStatusSuccess = createAction(
  '[Partner Code] Change Partner Points Status SUCCESS',
  props<{ partnerPoints: PartnerPoints }>()
);
export const changePointsStatusError = createAction('[Partner Code] Change Partner Points Status ERROR');

export const addPartnerPointsManually = createAction(
  '[Partner Code] Add Partner Points Manually',
  props<{ opts: AddPartnerPointsOpts }>()
);
export const addPartnerPointsManuallySuccess = createAction(
  '[Partner Code] Add Partner Points Manually SUCCESS',
  props<{ partnerPoints: PartnerPoints }>()
);
export const addPartnerPointsManuallyError = createAction('[Partner Code] Add Partner Points Manually ERROR');

export const removePartnerPoints = createAction(
  '[Partner Code] Remove Partner Points',
  props<{ opts: RemovePartnerPointsOpts }>()
);
export const removePartnerPointsSuccess = createAction(
  '[Partner Code] Remove Partner Points SUCCESS',
  props<{ removedPointsId: string }>()
);
export const removePartnerPointsError = createAction('[Partner Code] Remove Partner Points ERROR');

export const getGlobalTerms = createAction('[Partners] Get Global Terms');
export const getGlobalTermsSuccess = createAction(
  '[Partners] Get Global Terms SUCCESS',
  props<{ globalTerms: PartnerGlobalTerms }>()
);
export const getGlobalTermsError = createAction('[Partners] Get Global Terms ERROR');

export const updateGlobalTerms = createAction(
  '[Partners] Update Global Terms',
  props<{ opts: UpdateGlobalTermsOpts }>()
);

export const updateGlobalTermsSuccess = createAction(
  '[Partners] Update Global Terms SUCCESS',
  props<{ globalTerms: PartnerGlobalTerms }>()
);
export const updateGlobalTermsError = createAction('[Partners] Update Global Terms ERROR');
