import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

import { OfficesHttpService } from '@core/http/offices.http.service';

import * as officeActions from './office.actions';
import { parseError } from '@state/errors.parser';

@Injectable()
export class OfficeEffects {
  constructor(
    private actions$: Actions,
    private http: OfficesHttpService
  ) {}

  getOfficesSimpleList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(officeActions.getOfficesSimpleList),
      switchMap(() => {
        return this.http.getOfficesSimpleList().pipe(
          map(offices => officeActions.getOfficesSimpleListSuccess({ offices })),
          catchError(error => {
            const errorMessage = parseError(error, officeActions.getOfficesSimpleList.type);
            return of(officeActions.getOfficesSimpleListError({ errorMessage }));
          })
        );
      })
    )
  );

  searchOffices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(officeActions.searchOffices),
      switchMap(({ opts }) => {
        const { all, count, ...rest } = opts;
        return this.http.searchOffices(rest).pipe(
          map(response => officeActions.searchOfficesSuccess({ offices: response })),
          catchError(error => {
            const errorMessage = parseError(error, officeActions.searchOffices.type);
            return of(officeActions.searchOfficesError({ errorMessage }));
          })
        );
      })
    )
  );

  getOffice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(officeActions.getOffice),
      switchMap(({ officeId }) => {
        return this.http.getOffice(officeId).pipe(
          map(office => officeActions.getOfficeSuccess({ office })),
          catchError(error => {
            const errorMessage = parseError(error, officeActions.getOffice.type);
            return of(officeActions.getOfficeError({ errorMessage }));
          })
        );
      })
    )
  );

  createOffice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(officeActions.createOffice),
      switchMap(({ opts }) => {
        return this.http.createOffice(opts).pipe(
          map(office => officeActions.createOfficeSuccess({ office })),
          catchError(error => {
            const errorMessage = parseError(error, officeActions.createOffice.type);
            return of(officeActions.createOfficeError({ errorMessage }));
          })
        );
      })
    )
  );

  updateOffice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(officeActions.updateOffice),
      switchMap(({ officeId, opts }) => {
        return this.http.updateOffice(officeId, opts).pipe(
          map(office => officeActions.updateOfficeSuccess({ office })),
          catchError(error => {
            const errorMessage = parseError(error, officeActions.updateOffice.type);
            return of(officeActions.updateOfficeError({ errorMessage }));
          })
        );
      })
    )
  );

  removeOffice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(officeActions.removeOffice),
      switchMap(({ officeId }) => {
        return this.http.removeOffice(officeId).pipe(
          map(office => officeActions.removeOfficeSuccess({ office })),
          catchError(error => {
            const errorMessage = parseError(error, officeActions.removeOffice.type);
            return of(officeActions.removeOfficeError({ errorMessage }));
          })
        );
      })
    )
  );

  restoreOffice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(officeActions.restoreOffice),
      switchMap(({ officeId }) => {
        return this.http.restoreOffice(officeId).pipe(
          map(office => officeActions.restoreOfficeSuccess({ office })),
          catchError(error => {
            const errorMessage = parseError(error, officeActions.restoreOffice.type);
            return of(officeActions.restoreOfficeError({ errorMessage }));
          })
        );
      })
    )
  );

  toggleAssignedVoivodeship$ = createEffect(() =>
    this.actions$.pipe(
      ofType(officeActions.toggleAssignedVoivodeship),
      switchMap(({ opts }) => {
        return this.http.toggleAssignedVovoideship(opts).pipe(
          map(office => officeActions.toggleAssignedVoivodeshipSuccess({ office })),
          catchError(error => {
            const errorMessage = parseError(error, officeActions.toggleAssignedVoivodeship.type);
            return of(officeActions.toggleAssignedVoivodeshipError({ errorMessage }));
          })
        );
      })
    )
  );
}
