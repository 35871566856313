import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { CommonsHttpService } from '@core/http/commons.http.service';
import { ProcessHttpService } from '@core/http/process.http.service';
import { SnackbarService } from '@shared/snack-bar/snack-bar.service';
import { parseError } from '@state/errors.parser';
import * as commonsActions from './commons.actions';

@Injectable()
export class CommonseEffects {
  constructor(
    private actions$: Actions,
    private http: CommonsHttpService,
    private notification: SnackbarService,
    private processHttp: ProcessHttpService
  ) {}

  pingApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(commonsActions.pingApi),
      switchMap(() => {
        return this.http.pingApi().pipe(
          map(result => {
            if (result?.status === 'ok') {
              return commonsActions.pingApiSuccess();
            }
            return commonsActions.pingApiError();
          }),
          catchError(() => of(commonsActions.pingApiError()))
        );
      })
    )
  );

  getListOfAvailableProcesses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(commonsActions.getListOfAvailableProcesses),
      switchMap(() => {
        return this.processHttp.getListOfAvailableProcesses().pipe(
          map(processes => {
            return commonsActions.getListOfAvailableProcessesSuccess({ processes });
          }),
          catchError(error => {
            const errorMessage = parseError(error, commonsActions.getListOfAvailableProcesses.type);
            return of(commonsActions.getListOfAvailableProcessesError({ errorMessage }));
          })
        );
      })
    )
  );

  updateProcessFees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(commonsActions.updateProcessFees),
      switchMap(({ fees, processId }) => {
        return this.processHttp.updateProcessFees(processId, fees).pipe(
          map(updatedProcess => {
            return commonsActions.updateProcessFeesSuccess({ updatedProcess });
          }),
          catchError(error => {
            const errorMessage = parseError(error, commonsActions.updateProcessFees.type);
            return of(commonsActions.updateProcessFeesError({ errorMessage }));
          })
        );
      })
    )
  );

  getCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(commonsActions.getCountriesList),
      switchMap(() => {
        return this.http.getCountries().pipe(
          map(countriesList => commonsActions.getCountriesListSuccess({ countriesList })),
          catchError(() => of(commonsActions.getCountriesListError({ errorMessage: 'ERROR_FETCHING_COUNTRIES' })))
        );
      })
    )
  );

  getCountriesEU$ = createEffect(() =>
    this.actions$.pipe(
      ofType(commonsActions.getCountriesEUList),
      switchMap(() => {
        return this.http.getCountriesEU().pipe(
          map(countriesListEU => commonsActions.getCountriesEUListSuccess({ countriesListEU })),
          catchError(() => of(commonsActions.getCountriesEUListError({ errorMessage: 'ERROR_FETCHING_COUNTRIES' })))
        );
      })
    )
  );

  getLanguages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(commonsActions.getLanguages),
      switchMap(() => {
        return this.http.getLanguages().pipe(
          map(languages => commonsActions.getLanguagesSuccess({ languages })),
          catchError(error => {
            return of(commonsActions.getLanguagesError({ error }));
          })
        );
      })
    )
  );

  getAllVisitAssistants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(commonsActions.getAllVisitAssistants),
      switchMap(({}) => {
        return this.http.getAllVisitAssistants().pipe(
          map(visitAssistants => commonsActions.getAllVisitAssistantsSuccess({ visitAssistants })),
          catchError(() => {
            const errorMessage = 'ERROR_FETCHING_VISIT_ASSISTANTS';
            this.notification.showError(errorMessage);
            return of(commonsActions.getAllVisitAssistantsError({ errorMessage }));
          })
        );
      })
    )
  );

  createVisitAssistant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(commonsActions.createVisitAssistant),
      switchMap(({ opts }) => {
        return this.http.createVisitAssistant(opts).pipe(
          map(visitAssistant => commonsActions.createVisitAssistantSuccess({ visitAssistant })),
          catchError(() => {
            const errorMessage = 'ERROR_CREATING_VISIT_ASSISTANT';
            this.notification.showError(errorMessage);
            return of(commonsActions.getAllVisitAssistantsError({ errorMessage }));
          })
        );
      })
    )
  );

  removeVisitAssistant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(commonsActions.removeAssistant),
      switchMap(({ visitAssistantId }) => {
        return this.http.removeAssistant(visitAssistantId).pipe(
          map(() => commonsActions.removeAssistantSuccess({ removedVisitAssistantId: visitAssistantId })),
          catchError(() => {
            const errorMessage = 'ERROR_REMOVING_VISIT_ASSISTANT';
            this.notification.showError(errorMessage);
            return of(commonsActions.getAllVisitAssistantsError({ errorMessage }));
          })
        );
      })
    )
  );
}
