import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonsFacade } from '@state/commons';
import { AvailableLanguages } from '@constants';
import { CreateSupportMessageOpts, SupportChatInList } from '@interfaces';
import { ReplaySubject, take, takeUntil } from 'rxjs';
import { SupportChatFacade } from '@state/support-chat';
import { TranslateUtilsService } from '@core/services/translate-utils.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-follow-up-chat-modal',
  templateUrl: './follow-up-chat-modal.component.html',
  styleUrls: ['./follow-up-chat-modal.component.scss'],
})
export class FollowUpChatModalComponent implements OnInit {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  private followUpTextByLang: { [key: string]: string } = {};

  public languages$ = this.commonsFacade.availableLanguages$;
  public selectedLang: AvailableLanguages;

  public chatID: string;
  public dialogMessage: string;

  constructor(
    public dialogRef: MatDialogRef<FollowUpChatModalComponent>,
    private readonly supportChatFacade: SupportChatFacade,
    private readonly commonsFacade: CommonsFacade,
    private readonly translateUtilsService: TranslateUtilsService,
    private readonly translateService: TranslateService,
    private readonly nzMessage: NzMessageService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      supportChat: SupportChatInList;
    }
  ) {}

  ngOnInit(): void {
    this.chatID = this.data.supportChat.id;
    this.followUpTextByLang = this.translateUtilsService.getTranslations('FOLLOW_UP.FOLLOW_UP_TEXT_CHAT');
    this.selectedLang = 'en';
    this.onChangeLanguage(this.selectedLang);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onChangeLanguage(language: AvailableLanguages): void {
    this.dialogMessage = this.followUpTextByLang[language];
  }

  sendMessege(content: string): void {
    const opts: CreateSupportMessageOpts = {
      content,
      supportChatId: this.chatID,
      attachments: [],
    };

    this.supportChatFacade.createSupportMessageSuccess$.pipe(take(1), takeUntil(this.destroy$)).subscribe(() => {
      this.nzMessage.success(
        this.translateService.instant('FOLLOW_UP.FOLLOW_UP_SUCCESS', {
          username: this.data.supportChat.supportedUser.name,
        })
      );
      this.closeDialog();
    });

    this.supportChatFacade.createSupportMessage(opts);
  }
}
