import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, take, takeUntil, withLatestFrom } from 'rxjs/operators';

import { Location } from '@angular/common';
import { DateAdapter } from '@angular/material/core';
import { AvailableLanguages, allEmployeeRoles, patterns } from '@constants';
import { LocalStorageService } from '@core/services/local-storage.service';
import { environment } from '@environment';
import { Language, Office, Purchase, PurchaseStatus, ROLES_KEYS, User, Voivodeship } from '@interfaces';
import { PurchaseTags } from '@shared/purchase-tags/service-level-tag.component';
import { CommonsFacade } from '@state/commons';
import { OfficeFacade } from '@state/office';
import { PurchaseFacade } from '@state/purchase';
import { RouterFacade } from '@state/router';
import { UserAssetsFacade } from '@state/user-assets';
import { UserAuthFacade } from '@state/user-auth';
import { VoivodeshipsFacade } from '@state/voivodeships';
import { DeleteAccountModalService } from './delete-account-modal/delete-account-modal.service';
import { AnalyticsService } from '@core/services/analytics.service';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new ReplaySubject(1);

  public myself$: Observable<User | null> = this.userAuthFacade.myself$;
  public languages$: Observable<Language[]> = this.commonsFacade.availableLanguages$;
  public voivodeships$: Observable<Voivodeship[]> = this.voivodeshipsFacade.availableVoivodeships$;
  public loading$: Observable<boolean> = this.userAuthFacade.loading$;
  public offices$: Observable<Office[]> = this.officeFacade.offices$;
  public updateSuccess$: Observable<{ updatedUser: User }> = this.userAuthFacade.userUpdateSuccess$;
  public shouldShowOfficeField = false;
  public uncacheParam = Date.now();

  // @TODO add proper typing to FormGroup
  public form: FormGroup<any> = this.fb.group({
    email: ['', Validators.compose([Validators.required, Validators.email])],
    officeId: [null],
    languageId: ['', Validators.compose([Validators.required])],
    name: ['', Validators.compose([Validators.maxLength(200), Validators.pattern(patterns.PLNameValidator)])],
    telephoneNumber: [
      '',
      Validators.compose([
        Validators.required,
        Validators.maxLength(100),
        Validators.pattern(patterns.PhoneNumberValidator),
      ]),
    ],
  });

  public API_URL = environment.API_URL;
  public ROLES_KEYS = ROLES_KEYS;
  public currLang: AvailableLanguages;

  public showPurchaseList = false;
  public purchaseList$: Observable<Purchase[]>;
  public loadingPurchaseList$: Observable<boolean>;
  public PurchaseStatus = PurchaseStatus;
  protected readonly PurchaseTags = PurchaseTags;

  constructor(
    private readonly userAuthFacade: UserAuthFacade,
    private readonly commonsFacade: CommonsFacade,
    private readonly voivodeshipsFacade: VoivodeshipsFacade,
    private readonly fb: FormBuilder,
    private readonly translateService: TranslateService,
    private readonly routerFacade: RouterFacade,
    private readonly dateAdapter: DateAdapter<Date>,
    private readonly ls: LocalStorageService,
    private readonly location: Location,
    private readonly officeFacade: OfficeFacade,
    private readonly purchaseFacade: PurchaseFacade,
    private readonly userAssets: UserAssetsFacade,
    private readonly deleteAccountModal: DeleteAccountModalService,
    private readonly analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.currLang = this.translateService.currentLang as AvailableLanguages;
    this.translateService.onLangChange
      .pipe(withLatestFrom(this.languages$), takeUntil(this.destroy$))
      .subscribe(([{ lang }, langs]) => {
        this.currLang = lang as AvailableLanguages;

        const langId = langs.find(({ key }) => key === lang)?.id;
        if (langId) {
          this.form.get('languageId').setValue(langId);
        }
      });

    this.myself$
      .pipe(
        filter(myself => !!myself?.id && !!myself?.email),
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe(myself => {
        this.form.patchValue({
          email: myself.email,
          languageId: myself.languageId,
          companyId: myself.companyId,
          name: myself.name,
          telephoneNumber: myself.telephoneNumber,
          officeId: myself.office?.id || null,
        });

        const myRoleKey = myself.role.key;
        if (myRoleKey !== ROLES_KEYS.Admin) {
          this.form.get('email')?.disable();
          this.form.get('officeId')?.disable();
        }
        if (myRoleKey === ROLES_KEYS.Foreigner && myself.company.purchaseEnabled) {
          this.setupPurchaseList();
        }

        // show office field for these roles (neverming if disabled)
        this.shouldShowOfficeField = allEmployeeRoles.includes(myself.role.key as ROLES_KEYS);
        if (this.shouldShowOfficeField) {
          this.officeFacade.getOfficesSimpleList();
        }

        this.form
          .get('languageId')
          ?.valueChanges.pipe(withLatestFrom(this.languages$), takeUntil(this.destroy$))
          .subscribe(([langId, languages]) => {
            const pickedLanguage = languages.find(langObj => langObj.id === langId);

            if (!pickedLanguage) {
              console.error('Language picked by user does not exist in languages$ collection');
              return;
            }

            this.translateService.use(pickedLanguage.key);
            this.ls.setUserLang(pickedLanguage);
            if (pickedLanguage.key === 'pl') {
              this.dateAdapter.setLocale('pl-PL');
            }
            if (pickedLanguage.key === 'en') {
              this.dateAdapter.setLocale('en-GB');
            }
          });
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public goBack(): void {
    this.location.back();
  }

  public submitProfileForm(): void {
    this.myself$
      .pipe(
        filter(myself => !!myself?.role?.key),
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe(myself => {
        const myRoleKey = myself.role.key;

        const formValue = { ...this.form.value };
        // we don't want empty/null values!
        // and we're sure there are no desired falsy values (like number 0)
        Object.keys(formValue).forEach(key => {
          if (!formValue[key]) {
            delete formValue[key];
          }
        });

        // ADMINS
        if (myRoleKey === ROLES_KEYS.Admin) {
          this.form.markAsPristine();
          this.userAuthFacade.updateAdminself(myself.id as string, formValue);
          return;
        }

        // EMPLOYEES
        if (
          [ROLES_KEYS.Employee, ROLES_KEYS.Team_Leader, ROLES_KEYS.Field_Consultant].includes(myRoleKey as ROLES_KEYS)
        ) {
          delete formValue.email;
          delete formValue.officeId;
          this.form.markAsPristine();
          this.userAuthFacade.updateMyself(formValue);
          return;
        }

        // FOREIGNERS, MANAGERS and rest
        delete formValue.email;
        delete formValue.officeId;
        delete formValue.surname;
        this.form.markAsPristine();
        this.userAuthFacade.updateMyself(formValue);
      });
  }

  public deleteAccount(): void {
    this.deleteAccountModal.open();
  }

  public regenerateInitialsAvatar(): void {
    this.userAuthFacade.regenerateInitialsAvatar();
    this.uncacheParam = Date.now();
  }

  public downloadPurchaseDocument(userAssetId: string): void {
    this.userAssets.downloadMyAsset(userAssetId);
    this.analyticsService.trackEvent('user_event', 'user_download_purchase_document');
  }

  private setupPurchaseList(): void {
    this.purchaseList$ = this.purchaseFacade.purchaseList$;
    this.loadingPurchaseList$ = this.purchaseFacade.loadingPurchaseList$;

    this.purchaseFacade.getMyPurchaseList();
    this.showPurchaseList = true;
  }
}
