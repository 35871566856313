import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { SUPPORTED_LANGS } from '@constants';
import { TranslateUtilsService } from '@core/services/translate-utils.service';

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  constructor(
    private readonly translateService: TranslateService,
    private readonly translateUtilsService: TranslateUtilsService
  ) {}

  public ensureLangsLoaded(): Promise<boolean> {
    return this.waitForLangsLoaded(SUPPORTED_LANGS)
      .then(() => this.translateUtilsService.saveAllTranslations())
      .then(() => {
        this.translateService.setDefaultLang('pl');
        return true;
      });
  }

  private waitForLangsLoaded(langsArray: string[]): Promise<boolean> {
    let intervalId: any;
    this.translateService.addLangs(langsArray);
    return new Promise(resolve => {
      intervalId = setInterval(() => {
        this.checkIfLangsAvailable(langsArray).then(allAvailable => {
          if (allAvailable) {
            clearInterval(intervalId);
            intervalId = null;
            resolve(true);
            return;
          }
        });
      }, 500);
    });
  }
  private checkIfLangsAvailable(langsArray: string[]): Promise<boolean> {
    return Promise.all(
      langsArray.map(langKey => {
        const availableLangs = this.translateService.getLangs();
        if (availableLangs.includes(langKey)) {
          // eslint-disable-next-line no-console
          console.info(`Language '${langKey}' loaded`);
          return Promise.resolve(true);
        }

        console.warn(`Still waiting for languge '${langKey}'`);
        return Promise.resolve(false);
      })
    ).then(loadingLangsResults => {
      return loadingLangsResults.every(result => result);
    });
  }
}
