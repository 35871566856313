import { Injectable } from '@angular/core';
import {
  AddPartnerPointsOpts,
  CancelPayoutOpts,
  ChangePayoutStatusOpts,
  ChangePointsStatusOpts,
  DiscountCode,
  EditPartnerCodeOpts,
  PartnerPointsPayout,
  Purchase,
  RemovePartnerPointsOpts,
  SearchPartnersOpts,
  SearchPayoutsOpts,
  SumOfPartnerPoints,
  UpdateGlobalTermsOpts,
} from '@interfaces';
import { Actions, ofType } from '@ngrx/effects';

import { Store } from '@ngrx/store';
import { AppState } from '@state';
import { filter, lastValueFrom, map, take, takeUntil } from 'rxjs';
import * as pcActions from './partner-codes.actions';
import * as pcSelectors from './partner-codes.selectors';

@Injectable()
export class PartnerCodesFacade {
  public loading$ = this.store.select(pcSelectors.selectLoading);
  public loadingList$ = this.store.select(pcSelectors.selectLoadingList);

  public partnerCodeDetails$ = this.store.select(pcSelectors.selectPartnerCodeDetails);
  public codeGenerationInProgress$ = this.store.select(pcSelectors.selectCodeGenerationInProgress);
  public partnerPointsSum$ = this.store.select(pcSelectors.selectSumOfPartnerPoints);
  public partnerCodeUsage$ = this.store.select(pcSelectors.selectPartnerCodeUsage);
  public partnerPayouts$ = this.store.select(pcSelectors.selectPartnerPayouts);
  public allPartnerPoints$ = this.store.select(pcSelectors.selectAllPartnerPoints);

  public partners$ = this.store.select(pcSelectors.selectPartners);
  public partnersPagination$ = this.store.select(pcSelectors.selectPartnersPagination);

  public payouts$ = this.store.select(pcSelectors.selectPayouts);
  public payoutsPagination$ = this.store.select(pcSelectors.selectPayoutsPagination);

  public globalTerms$ = this.store.select(pcSelectors.selectGlobalTerms);

  public getPartnerCodeDetailsError$ = this.actions.pipe(ofType(pcActions.getPartnerCodeDetailsError));
  public generatePartnerCodeForUserError$ = this.actions.pipe(ofType(pcActions.generatePartnerCodeForUserError));
  public generatePartnerCodeForUserSuccess$ = this.actions.pipe(ofType(pcActions.generatePartnerCodeForUserSuccess));
  public regeneratePartnerCodeSuccess$ = this.actions.pipe(ofType(pcActions.regeneratePartnerCodeForUserSuccess));
  public regeneratePartnerCodeError$ = this.actions.pipe(ofType(pcActions.regeneratePartnerCodeForUserError));

  public setPartnerCodeSuccess$ = this.actions.pipe(ofType(pcActions.setDifferentCodeForPartnerSuccess));
  public setPartnerCodeError$ = this.actions.pipe(ofType(pcActions.setDifferentCodeForPartnerError));

  public editPartnerCodeError$ = this.actions.pipe(ofType(pcActions.editPartnerCodeError));
  public editPartnerCodeSuccess$ = this.actions.pipe(ofType(pcActions.editPartnerCodeSuccess));
  public codeGenerationSuccess$ = this.actions.pipe(ofType(pcActions.regeneratePartnerCodeForUserSuccess));
  public codeGenerationError$ = this.actions.pipe(ofType(pcActions.regeneratePartnerCodeForUserError));

  public getAllPartnerPointsSuccess$ = this.actions.pipe(ofType(pcActions.getAllPartnerPointsSuccess));
  public getAllPartnerPointsError$ = this.actions.pipe(ofType(pcActions.getAllPartnerPointsError));

  public getPartnerPointsSumError$ = this.actions.pipe(ofType(pcActions.getPartnerPointsSumError));
  public getPartnerCodeUsageError$ = this.actions.pipe(ofType(pcActions.getPartnerCodeUsageError));

  public pcError$ = this.actions.pipe(ofType(pcActions.generatePartnerCodeForUserError));

  constructor(
    private store: Store<AppState>,
    private actions: Actions
  ) {}

  public searchPartners(opts: SearchPartnersOpts): void {
    this.store.dispatch(pcActions.searchPartners({ opts }));
  }

  public searchPayouts(opts: SearchPayoutsOpts): void {
    this.store.dispatch(pcActions.searchPayouts({ opts }));
  }

  public getPartnerCodeDetails(userId: string): void {
    this.store.dispatch(pcActions.getPartnerCodeDetails({ userId }));
  }
  public generatePartnerCodeForUser(userId: string): void {
    this.store.dispatch(pcActions.generatePartnerCodeForUser({ userId }));
  }
  public editCodeOfPartner(opts: EditPartnerCodeOpts): void {
    this.store.dispatch(pcActions.editPartnerCode({ opts }));
  }
  public regeneratePartnerCodeForUser(userId: string): void {
    this.store.dispatch(pcActions.regeneratePartnerCodeForUser({ userId }));
  }

  public setPartnerCodeForUser(userId: string, code: string): void {
    this.store.dispatch(pcActions.setDifferentCodeForPartner({ userId, code }));
  }

  public getPartnerPointsSum(userId: string): void {
    this.store.dispatch(pcActions.getPartnerPointsSum({ userId }));
  }
  public getAllPartnerPoints(userId: string): void {
    this.store.dispatch(pcActions.getAllPartnerPoints({ userId }));
  }
  public changePayoutStatus(opts: ChangePayoutStatusOpts): void {
    this.store.dispatch(pcActions.changePayoutStatus({ opts }));
  }
  public cancelPayout(opts: CancelPayoutOpts): void {
    this.store.dispatch(pcActions.cancelPayout({ opts }));
  }
  public getPartnerPayoutsHistory(userId: string): void {
    this.store.dispatch(pcActions.getPartnerPayoutsHistory({ userId }));
  }
  public changePointsStatus(opts: ChangePointsStatusOpts): void {
    this.store.dispatch(pcActions.changePointsStatus({ opts }));
  }
  public addPartnerPointsManually(opts: AddPartnerPointsOpts): void {
    this.store.dispatch(pcActions.addPartnerPointsManually({ opts }));
  }
  public removePartnerPoints(opts: RemovePartnerPointsOpts): void {
    this.store.dispatch(pcActions.removePartnerPoints({ opts }));
  }
  public getGlobalTerms(): void {
    this.store.dispatch(pcActions.getGlobalTerms());
  }
  public updateGlobalTerms(opts: UpdateGlobalTermsOpts): void {
    this.store.dispatch(pcActions.updateGlobalTerms({ opts }));
  }

  public getPartnerCodeDetails$(userId: string): Promise<DiscountCode> {
    const promise = lastValueFrom(
      this.actions.pipe(ofType(pcActions.getPartnerCodeDetailsSuccess)).pipe(
        filter(({ userId: requestedForUserId }) => {
          return requestedForUserId === userId;
        }),
        take(1),
        map(({ discountCode }) => discountCode),
        takeUntil(this.getPartnerCodeDetailsError$)
      )
    );

    this.store.dispatch(pcActions.getPartnerCodeDetails({ userId }));
    return promise;
  }

  public getPartnerPointsSum$(userId: string): Promise<SumOfPartnerPoints> {
    const promise = lastValueFrom(
      this.actions.pipe(ofType(pcActions.getPartnerPointsSumSuccess)).pipe(
        filter(({ userId: requestedForUserId }) => {
          return requestedForUserId === userId;
        }),
        take(1),
        map(({ sumOfPoints }) => sumOfPoints),
        takeUntil(this.getPartnerPointsSumError$)
      )
    );

    this.store.dispatch(pcActions.getPartnerPointsSum({ userId }));
    return promise;
  }

  public getPartnerCodeUsage$(userId: string): Promise<Partial<Purchase[]>> {
    const promise = lastValueFrom(
      this.actions.pipe(ofType(pcActions.getPartnerCodeUsageSuccess)).pipe(
        filter(({ userId: requestedForUserId }) => {
          return requestedForUserId === userId;
        }),
        take(1),
        map(({ purchases }) => purchases),
        takeUntil(this.getPartnerCodeUsageError$)
      )
    );

    this.store.dispatch(pcActions.getPartnerCodeUsage({ userId }));
    return promise;
  }

  public getPartnerPayoutsHistory$(userId: string): Promise<PartnerPointsPayout[]> {
    const promise = lastValueFrom(
      this.actions.pipe(ofType(pcActions.getPartnerPayoutsHistorySuccess)).pipe(
        filter(({ userId: requestedForUserId }) => {
          return requestedForUserId === userId;
        }),
        take(1),
        map(({ partnerPayouts }) => partnerPayouts),
        takeUntil(this.actions.pipe(ofType(pcActions.getPartnerPayoutsHistoryError)))
      )
    );

    this.store.dispatch(pcActions.getPartnerPayoutsHistory({ userId }));
    return promise;
  }
}
