import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';

import { ChatHttpService } from '@core/http/chat.http.service';
import { parseError } from '@state/errors.parser';

import { UserAssetsHttpService } from '@core/http/user-assets.http.service';
import { SingleFileUploadResult, UserAsset } from '@interfaces';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import * as chatActions from './chat.actions';
import { GetFileService } from '@core/services/get-file.service';

@Injectable()
export class ChatEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly chatHttp: ChatHttpService,
    private readonly assetsHttp: UserAssetsHttpService,
    private readonly nzMessage: NzMessageService,
    private readonly translateService: TranslateService,
    private readonly getFileService: GetFileService
  ) {}

  getMyChat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(chatActions.getMyChat),
      switchMap(() => {
        return this.chatHttp.getMyChat().pipe(
          map(({ messages, supportChat }) => {
            return chatActions.getMyChatSuccess({ messages, supportChat });
          }),
          catchError(error => {
            this.nzMessage.error(this.translateService.instant('NT3.ERROR_DOWNLOADING_CHAT_DATA'));

            const errorMessage = parseError(error, chatActions.getMyChat.type);
            return of(chatActions.getMyChatError({ errorMessage }));
          })
        );
      })
    )
  );

  addChatMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(chatActions.addChatMessage),
      switchMap(({ opts }) => {
        return this.chatHttp.addChatMessage(opts).pipe(
          map(message => {
            return chatActions.addChatMessageSuccess({ message });
          }),
          catchError(error => {
            this.nzMessage.error(this.translateService.instant('NT3.ERROR_SENDING_MESSAGE'));

            const errorMessage = parseError(error, chatActions.addChatMessage.type);
            return of(chatActions.addChatMessageError({ errorMessage }));
          })
        );
      })
    )
  );

  uploadAttachments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(chatActions.uploadAttachments),
      concatMap(({ opts }) => {
        const { files } = opts;
        const resultsObj: { [key: string]: Observable<SingleFileUploadResult> } = files.reduce(
          (acc, currFile) => {
            acc[currFile.fileOriginalName] = this.assetsHttp.uploadMessageAttachment({ file: currFile.file }).pipe(
              map(uploadResult => {
                return { errorMessage: null, uploaded: true, userAsset: uploadResult };
              }),
              catchError(error => {
                const mssg = this.translateService.instant('NT2.ERROR_UPLOADING_FILE', {
                  fileName: currFile.fileOriginalName,
                });
                this.nzMessage.error(mssg);
                const errorMessage = parseError(error, chatActions.uploadAttachments.type);
                return of({
                  errorMessage,
                  uploaded: false,
                  userAsset: { fileOriginalName: currFile.fileOriginalName } as UserAsset,
                });
              })
            );
            return acc;
          },
          {} as { [key: string]: Observable<SingleFileUploadResult> }
        );

        return forkJoin(resultsObj).pipe(
          map(uploadedFiles => {
            return chatActions.uploadAttachmentsSuccess({ opts: { uploadedFiles } });
          }),
          catchError(err => {
            const errorMessage = parseError(err, chatActions.uploadAttachments.type);
            return of(chatActions.uploadAttachmentsError({ opts: { errorMessage } }));
          })
        );
      })
    )
  );

  downloadAttachmentFromChat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(chatActions.downloadAttachmentFromChat),
      switchMap(({ messageId, attachmentId }) => {
        return this.chatHttp.downloadAttachmentFromChat(messageId, attachmentId).pipe(
          map(response => {
            if (this.getFileService.getFile(response)) {
              return chatActions.downloadAttachmentFromChatSuccess();
            } else {
              return chatActions.downloadAttachmentFromChatError();
            }
          }),
          catchError(() => {
            this.nzMessage.error(this.translateService.instant('NT3.ERROR_DOWNLOADING_ATTACHMENT'));
            return of(chatActions.downloadAttachmentFromChatError());
          })
        );
      })
    )
  );

  removeAttachmentFromUploading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(chatActions.removeAttachmentFromUploading),
      switchMap(({ opts }) => {
        return this.assetsHttp.removeMyAsset(opts).pipe(
          map(() => {
            return chatActions.removeAttachmentFromUploadingSuccess({ opts });
          }),
          catchError(error => {
            this.nzMessage.error(this.translateService.instant('NT2.ERROR_REMOVING_FILE'));
            const errorMessage = parseError(error, chatActions.removeAttachmentFromUploading.type);
            return of(chatActions.removeAttachmentFromUploadingError({ opts: { ...opts, errorMessage } }));
          })
        );
      })
    )
  );
}
