import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, delay, map, switchMap } from 'rxjs/operators';

import { PurchaseHttpService } from '@core/http/purchase.http.service';

import { ValidatePurchaseError } from '@interfaces';
import { SnackbarService } from '@shared/snack-bar/snack-bar.service';
import * as purchaseActions from './purchase.actions';

@Injectable()
export class PurchaseEffects {
  constructor(
    private actions$: Actions,
    private http: PurchaseHttpService,
    private snackService: SnackbarService
  ) {}

  getAvailableProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(purchaseActions.getAvailableProducts),
      switchMap(() => {
        return this.http.getAvailableProducts().pipe(
          map(products => purchaseActions.getAvailableProductsSuccess({ products })),
          catchError(() => {
            this.snackService.showError('PURCHASE.ERROR.ERROR_FETCHING_PRODUCTS');
            return of(purchaseActions.getAvailableProductsError());
          })
        );
      })
    )
  );

  getAvailablePaymentMethods$ = createEffect(() =>
    this.actions$.pipe(
      ofType(purchaseActions.getAvailablePaymentMethods),
      switchMap(({ opts }) => {
        return this.http.getAvailablePaymentMethods(opts).pipe(
          map(p24AvailablePaymentMethods =>
            purchaseActions.getAvailablePaymentMethodsSuccess({ paymentMethods: p24AvailablePaymentMethods })
          ),
          catchError(() => {
            this.snackService.showError('PURCHASE.ERROR.ERROR_FETCHING_AVAILABLE_PAYMENT_METHODS_FROM_P24');
            return of(purchaseActions.getAvailablePaymentMethodsError());
          })
        );
      })
    )
  );

  validatePurchase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(purchaseActions.validatePurchase),
      delay(450), // force delay just to show fancy 'calculating your purchase' progress-page
      switchMap(({ opts }) => {
        return this.http.validatePurchase(opts).pipe(
          map(purchase => purchaseActions.validatePurchaseSuccess({ purchase })),
          catchError(error => {
            const validationError: ValidatePurchaseError = {
              message: error?.error?.message
                ? `PURCHASE.ERROR.${error?.error?.message}`
                : 'PURCHASE.ERROR.PURCHASE_VALIDATION_ERROR',
              status: 400,
              data: { code: error?.error?.data?.code || '', min: error?.error?.data?.min || 0 },
            };

            return of(purchaseActions.validatePurchaseError({ error: validationError }));
          })
        );
      })
    )
  );

  newPurchase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(purchaseActions.newPurchase),
      delay(450), // force delay just to show fancy spinner 'creating your purchase'
      switchMap(({ opts }) => {
        return this.http.newPurchase(opts).pipe(
          map(purchase => purchaseActions.newPurchaseSuccess({ purchase })),
          catchError(() => {
            this.snackService.showError('PURCHASE.ERROR.ERROR_CREATING_NEW_PURCHASE');
            return of(purchaseActions.newPurchaseError());
          })
        );
      })
    )
  );

  getMyPurchase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(purchaseActions.getMyPurchase),
      switchMap(({ purchaseId }) => {
        return this.http.getMyPurchase(purchaseId).pipe(
          map(purchase => purchaseActions.getMyPurchaseSuccess({ purchase })),
          catchError(() => {
            this.snackService.showError('PURCHASE.ERROR.ERROR_FETCHING_PURCHASE_DATA');
            return of(purchaseActions.getMyPurchaseError());
          })
        );
      })
    )
  );

  setPaymentStarted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(purchaseActions.setPaymentStarted),
      switchMap(({ purchaseId }) => {
        return this.http.setPaymentStarted(purchaseId).pipe(
          map(updatedPurchase => purchaseActions.setPaymentStartedSuccess({ updatedPurchase })),
          catchError(() => {
            this.snackService.showError('PURCHASE.ERROR.ERROR_UPDATING_PURCHASE_STATUS');
            return of(purchaseActions.setPaymentStartedError({ errorMessage: 'ERROR_UPDATING_PURCHASE_STATUS' }));
          })
        );
      })
    )
  );

  verifyPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(purchaseActions.verifyPayment),
      switchMap(({ purchaseId }) => {
        return this.http.verifyPayment(purchaseId).pipe(
          map(updatedPurchase => purchaseActions.verifyPaymentSuccess({ updatedPurchase })),
          catchError(() => {
            this.snackService.showError('PURCHASE.ERROR.ERROR_VERIFYING_PAYMENT');
            return of(purchaseActions.verifyPaymentError({ errorMessage: 'ERROR_VERIFYING_PAYMENT' }));
          })
        );
      })
    )
  );

  getMyPurchaseList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(purchaseActions.getMyPurchaseList),
      switchMap(() => {
        return this.http.getPurchaseList().pipe(
          map(purchaseList => purchaseActions.getMyPurchaseListSuccess({ purchaseList })),
          catchError(() => {
            this.snackService.showError('PURCHASE.ERROR.CANNOT_FETCH_PURCHASE_LIST');
            return of(purchaseActions.getMyPurchaseListError());
          })
        );
      })
    )
  );

  getPurchaseListOfUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(purchaseActions.getPurchaseListOfUser),
      switchMap(({ buyerId }) => {
        return this.http.getPurchaseListOfUser(buyerId).pipe(
          map(purchaseList => purchaseActions.getPurchaseListOfUserSuccess({ purchaseList })),
          catchError(() => {
            this.snackService.showError('PURCHASE.ERROR.CANNOT_FETCH_PURCHASE_LIST');
            return of(purchaseActions.getPurchaseListOfUserError());
          })
        );
      })
    )
  );
}
