import { Injectable } from '@angular/core';
import {
  BackOfficeApplicationsRestorePayload,
  BackOfficeApplicationsSoftDeletePayload,
  BackOfficeGenerateApplicationReport,
  BackOfficeGenerateTransactionsReport,
  BackOfficeGetApplicationsPayload,
  BackOfficeGetTransactionsPayload,
  BackOfficeGetUsersPayload,
  BackOfficeUsersActivateAccountPayload,
  BackOfficeUsersChangePasswordPayload,
  BackOfficeUsersChangePasswordReturn,
  BackOfficeUsersDeactivateAccountPayload,
  BackOfficeUsersPermDeletePayload,
  BackOfficeUsersRestoreAccountPayload,
  BackOfficeUsersSoftDeletePayload,
  Company,
  Language,
  PaginatedResponse,
  Purchase,
  Role,
  User,
  UserProcess,
} from '@interfaces';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class BackOfficeHttpService {
  private API_URL = environment.API_URL;

  constructor(private http: HttpClient) {}

  public getRoles(): Observable<Role[]> {
    const url = `${this.API_URL}/back-office/roles`;
    return this.http.get<Role[]>(url);
  }

  public getLanguages(): Observable<Language[]> {
    const url = `${this.API_URL}/back-office/languages`;
    return this.http.get<Language[]>(url);
  }

  public getCompanies(): Observable<Company[]> {
    const url = `${this.API_URL}/back-office/companies`;
    return this.http.get<Company[]>(url);
  }

  public getEmployees(): Observable<User[]> {
    const url = `${this.API_URL}/back-office/employees`;
    return this.http.get<User[]>(url);
  }

  // ############
  // Users
  // ############

  public getUsers(payload: BackOfficeGetUsersPayload): Observable<PaginatedResponse<User>> {
    const url = `${this.API_URL}/back-office/users/search`;
    return this.http.post<PaginatedResponse<User>>(url, payload);
  }

  public usersActivateAccount(
    payload: BackOfficeUsersActivateAccountPayload
  ): Observable<{ activatedAccountID: string }> {
    const { userID, password, passwordConfirm } = payload;
    const url = `${this.API_URL}/back-office/users/${userID}/activate`;
    return this.http.post<{ activatedAccountID: string }>(url, { password, passwordConfirm });
  }

  public usersDeactivateAccount(
    payload: BackOfficeUsersDeactivateAccountPayload
  ): Observable<{ deactivatedAccountID: string }> {
    const url = `${this.API_URL}/back-office/users/${payload.userID}/deactivate`;
    return this.http.get<{ deactivatedAccountID: string }>(url);
  }

  public usersRestoreAccount(payload: BackOfficeUsersRestoreAccountPayload): Observable<{ restoredAccountID: string }> {
    const url = `${this.API_URL}/back-office/users/${payload.userID}/restore`;
    return this.http.get<{ restoredAccountID: string }>(url);
  }

  public usersChangePassword(
    payload: BackOfficeUsersChangePasswordPayload
  ): Observable<BackOfficeUsersChangePasswordReturn> {
    const { userID, mode, password, passwordConfirm } = payload;
    const url = `${this.API_URL}/back-office/users/${userID}/change-password`;
    return this.http.post<BackOfficeUsersChangePasswordReturn>(url, { mode, password, passwordConfirm });
  }

  public usersSoftDelete(payload: BackOfficeUsersSoftDeletePayload): Observable<{ deletedUsersID: string[] }> {
    const url = `${this.API_URL}/back-office/users/soft-delete`;
    return this.http.delete<{ deletedUsersID: string[] }>(url, { body: payload });
  }

  public usersPermDelete(payload: BackOfficeUsersPermDeletePayload): Observable<{ deletedUsersID: string[] }> {
    const url = `${this.API_URL}/back-office/users/perm-delete`;
    return this.http.delete<{ deletedUsersID: string[] }>(url, { body: payload });
  }

  // ############
  // Transactions
  // ############

  public getTransactions(payload: BackOfficeGetTransactionsPayload): Observable<PaginatedResponse<Purchase>> {
    const url = `${this.API_URL}/back-office/transactions/search/`;
    return this.http.post<PaginatedResponse<Purchase>>(url, payload);
  }

  public downloadPurchaseDocument(opts: { transactionID: string }): Observable<any> {
    const url = `${this.API_URL}/back-office/transactions/${opts.transactionID}/purchase-document`;
    return this.http.get(url, { responseType: 'blob', observe: 'response' });
  }

  public generateTransactionsReport(payload: BackOfficeGenerateTransactionsReport): Observable<any> {
    const url = `${this.API_URL}/back-office/transactions/generate-report`;
    return this.http.post(url, payload, { responseType: 'blob', observe: 'response' });
  }

  // ############
  // Applications
  // ############

  public getApplications(payload: BackOfficeGetApplicationsPayload): Observable<PaginatedResponse<UserProcess>> {
    const url = `${this.API_URL}/back-office/applications/search/`;
    return this.http.post<PaginatedResponse<UserProcess>>(url, payload);
  }

  public generateApplicationsReport(payload: BackOfficeGenerateApplicationReport): Observable<any> {
    const url = `${this.API_URL}/back-office/applications/generate-report`;
    return this.http.post(url, payload, { responseType: 'blob', observe: 'response' });
  }

  public applicationsSoftDelete(
    payload: BackOfficeApplicationsSoftDeletePayload
  ): Observable<{ deletedApplicationsID: string[] }> {
    const url = `${this.API_URL}/back-office/applications/soft-delete`;
    return this.http.delete<{ deletedApplicationsID: string[] }>(url, { body: payload });
  }

  public applicationsRestore(
    payload: BackOfficeApplicationsRestorePayload
  ): Observable<{ deletedApplicationID: string }> {
    const url = `${this.API_URL}/back-office/applications/restore`;
    return this.http.post<{ deletedApplicationID: string }>(url, payload);
  }
}
