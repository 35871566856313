import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';

import { UserAssetsHttpService } from '@core/http/user-assets.http.service';
import { SingleFileUploadResult, UserAsset } from '@interfaces';
import { parseError } from '@state/errors.parser';

import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import * as reviewActions from './review.actions';
import { GetFileService } from '@core/services/get-file.service';

@Injectable()
export class ReviewEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly assetsHttp: UserAssetsHttpService,
    private readonly nzMessage: NzMessageService,
    private readonly translateService: TranslateService,
    private readonly getFileService: GetFileService
  ) {}

  private showError(key: string, params?: any): void {
    const msg = this.translateService.instant(key, params || {});
    this.nzMessage.error(msg, { nzDuration: 5000 });
  }

  getMyDocumentsSentToReview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reviewActions.getMyDocumentsSentToReview),
      switchMap(({ opts }) => {
        const { userProcessId } = opts;
        return this.assetsHttp.getMyDocumentsSentToReview(opts).pipe(
          map(userAssets => {
            return reviewActions.getMyDocumentsSentToReviewSuccess({ opts: { userAssets, userProcessId } });
          }),
          catchError(error => {
            this.showError('NT2.ERROR_FETCHING_DOCUMENTS_SENT_TO_REVIEW');
            const errorMessage = parseError(error, reviewActions.getMyDocumentsSentToReview.type);
            return of(reviewActions.getMyDocumentsSentToReviewError({ opts: { errorMessage, userProcessId } }));
          })
        );
      })
    )
  );

  uploadDocumentToReview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reviewActions.uploadDocumentToReview),
      concatMap(({ opts }) => {
        const { userProcessId, files } = opts;
        const resultsObj: { [key: string]: Observable<SingleFileUploadResult> } = files.reduce(
          (acc, currFile) => {
            acc[currFile.fileOriginalName] = this.assetsHttp
              .uploadDocumentToReview({ userProcessId, file: currFile.file })
              .pipe(
                map(uploadResult => {
                  return { errorMessage: null, uploaded: true, userAsset: uploadResult };
                }),
                catchError(error => {
                  this.showError('NT2.ERROR_UPLOADING_FILE', { fileName: currFile.fileOriginalName });
                  const errorMessage = parseError(error, reviewActions.uploadDocumentToReview.type);
                  return of({
                    errorMessage,
                    uploaded: false,
                    userAsset: { fileOriginalName: currFile.fileOriginalName } as UserAsset,
                  });
                })
              );
            return acc;
          },
          {} as { [key: string]: Observable<SingleFileUploadResult> }
        );

        return forkJoin(resultsObj).pipe(
          map(uploadedFiles => {
            return reviewActions.uploadDocumentToReviewSuccess({ opts: { userProcessId, uploadedFiles } });
          }),
          catchError(err => {
            const errorMessage = parseError(err, reviewActions.uploadDocumentToReview.type);
            return of(reviewActions.uploadDocumentToReviewError({ opts: { errorMessage, userProcessId } }));
          })
        );
      })
    )
  );

  downloadMyDocumentFromReview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reviewActions.downloadDocumentFromReview),
      switchMap(({ opts }) => {
        return this.assetsHttp.downloadMyAsset(opts).pipe(
          map(response => {
            if (this.getFileService.getFile(response)) {
              return reviewActions.downloadDocumentFromReviewSuccess({ opts });
            } else {
              return reviewActions.downloadDocumentFromReviewError({ opts });
            }
          }),
          catchError(error => {
            // Show notifciation with error message
            this.showError('NT2.ERROR_DOWNLOADING_FILE', { fileName: opts.fileOriginalName });
            const errorMessage = parseError(error, reviewActions.downloadDocumentFromReview.type);
            return of(reviewActions.downloadDocumentFromReviewError({ opts: { ...opts, errorMessage } }));
          })
        );
      })
    )
  );

  removeMyFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reviewActions.removeMyFile),
      switchMap(({ opts }) => {
        return this.assetsHttp.removeMyAsset(opts).pipe(
          map(() => {
            return reviewActions.removeMyFileSuccess({ opts });
          }),
          catchError(error => {
            // Show notifciation with error message
            this.showError('NT2.ERROR_REMOVING_FILE', { fileName: opts.fileOriginalName });
            const errorMessage = parseError(error, reviewActions.removeMyFile.type);
            return of(reviewActions.removeMyFileError({ opts: { ...opts, errorMessage } }));
          })
        );
      })
    )
  );
}
