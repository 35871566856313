import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionTimeoutService } from '@core/services/session-timeout.service';
import { LayoutService } from '@layout/layout.service';
import { RouterFacade } from '@state/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private tooManyRequestsTimeout: NodeJS.Timeout;

  private urlsToSkipUnauthorized: string[] = [`/auth/verify-account`, `/auth/set-new-password`];

  constructor(
    private readonly routerFacade: RouterFacade,
    private readonly sessionTimeoutService: SessionTimeoutService,
    private readonly layoutService: LayoutService
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      withCredentials: true,
    });

    return next.handle(request).pipe(
      map((httpReq: any) => {
        return httpReq;
      }),
      catchError((httpErrorResponse: HttpErrorResponse) => {
        const requestUrl = request.url;
        const skipErrorCheck = this.urlsToSkipUnauthorized.some(urlPart => requestUrl.toLowerCase().includes(urlPart));

        if (skipErrorCheck && httpErrorResponse.status === HttpStatusCode.Unauthorized) {
          return throwError(() => httpErrorResponse);
        }

        if (httpErrorResponse.status === HttpStatusCode.Unauthorized) {
          this.sessionTimeoutService.stopIdleWatch();
          this.routerFacade.changeRoute({ linkParams: ['login'] });
          return throwError(() => httpErrorResponse);
        }

        if (httpErrorResponse.status === HttpStatusCode.TooManyRequests) {
          this.layoutService.openInfoBar({
            type: 'warning',
            message: 'TOP_BAR.ERRORS.TOO_MANY_REQUESTS',
            action: 'CALLBACK',
            callback: () => {},
          });

          if (this.tooManyRequestsTimeout) {
            clearTimeout(this.tooManyRequestsTimeout);
            this.tooManyRequestsTimeout = null;
          }

          this.tooManyRequestsTimeout = setTimeout(() => {
            this.layoutService.closeInfoBar();
          }, 60000);
          return throwError(() => httpErrorResponse);
        }

        // this isn't Unauthorized nor TooManyRequests
        if (this.tooManyRequestsTimeout) {
          clearTimeout(this.tooManyRequestsTimeout);
          this.tooManyRequestsTimeout = null;
        }
        return throwError(() => httpErrorResponse);
      })
    );
  }
}
