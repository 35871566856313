<div class="stepper-container" #stepperContainer *ngIf="personalDataForm">
  <mat-stepper
    orientation="vertical"
    [linear]="false"
    [disableRipple]="true"
    (animationDone)="stepAnimationDone()"
    #mainStepper>
    <ng-template matStepperIcon="edit" let-index="index">
      {{ index + 1 }}
    </ng-template>
    <ng-template matStepperIcon="done" let-index="index">
      {{ index + 1 }}
    </ng-template>
    <ng-template matStepperIcon="number" let-index="index">
      {{ index + 1 }}
    </ng-template>
    <ng-template matStepperIcon="warn" let-index="index">
      {{ index + 1 }}
    </ng-template>

    <ng-container *ngIf="getStepOfForm('step1') as stepControl">
      <mat-step
        [stepControl]="stepControl"
        [completed]="stepControl.valid"
        [state]="
          mainStepper.selectedIndex === 0
            ? 'edit'
            : stepControl.touched && stepControl.valid
            ? 'done'
            : stepControl.touched && stepControl.invalid
            ? 'warn'
            : stepControl.pristine && stepControl.invalid
            ? 'number'
            : stepControl.pristine && stepControl.valid
            ? 'done'
            : 'number'
        ">
        <ng-template matStepLabel>
          {{ 'PERSONAL_DATA_FORM.PERSONAL-DATA' | translate }}
          <mat-icon
            (click)="initGuide(GUIDE_NAME.PERSONAL_DATA_FORM, 1)"
            matTooltip="{{ 'GUIDE.SHOW_INSTRUCTIONS' | translate }}"
            matTooltipClass="universalTooltip"
            matTooltipPosition="right"
            class="guide-open-button"
            fontSet="material-icons-outlined">
            live_help
          </mat-icon>
        </ng-template>
        <ng-template matStepContent>
          <app-temp-premit-step1
            *ngIf="mainStepper.selectedIndex === 0"
            [formGroup]="stepControl"
            [mode]="mode"
            (lastFieldTabPressed)="mainStepper.next()">
          </app-temp-premit-step1>
        </ng-template>
      </mat-step>
    </ng-container>

    <ng-container *ngIf="getStepOfForm('step2') as stepControl">
      <mat-step
        [stepControl]="stepControl"
        [completed]="stepControl.valid"
        [state]="
          mainStepper.selectedIndex === 1
            ? 'edit'
            : stepControl.touched && stepControl.valid
            ? 'done'
            : stepControl.touched && stepControl.invalid
            ? 'warn'
            : stepControl.pristine && stepControl.invalid
            ? 'number'
            : stepControl.pristine && stepControl.valid
            ? 'done'
            : 'number'
        ">
        <ng-template matStepLabel>
          {{ 'PERSONAL_DATA_FORM.PERSONAL-DETAILS' | translate }}
          <mat-icon
            (click)="initGuide(GUIDE_NAME.FORM_STEP_2, 2)"
            matTooltip="{{ 'GUIDE.SHOW_INSTRUCTIONS' | translate }}"
            matTooltipClass="universalTooltip"
            matTooltipPosition="right"
            class="guide-open-button"
            fontSet="material-icons-outlined">
            live_help
          </mat-icon>
        </ng-template>
        <ng-template matStepContent>
          <app-temp-premit-step2
            [formGroup]="stepControl"
            [userProcess]="userProcess"
            [mode]="mode"
            (firstInputShiftTabPressed)="mainStepper.previous()"
            (lastFieldTabPressed)="mainStepper.next()"
            (confirmVoivoChange)="confirmVoivoChange.emit()">
          </app-temp-premit-step2>
        </ng-template>
      </mat-step>
    </ng-container>

    <ng-container *ngIf="getStepOfForm('step3') as stepControl">
      <mat-step
        [stepControl]="stepControl"
        [completed]="stepControl.valid"
        [state]="
          mainStepper.selectedIndex === 2
            ? 'edit'
            : stepControl.touched && stepControl.valid
            ? 'done'
            : stepControl.touched && stepControl.invalid
            ? 'warn'
            : stepControl.pristine && stepControl.invalid
            ? 'number'
            : stepControl.pristine && stepControl.valid
            ? 'done'
            : 'number'
        ">
        <ng-template matStepLabel>
          {{ 'PERSONAL_DATA_FORM.STATEMENTS' | translate }}
          <mat-icon
            (click)="initGuide(GUIDE_NAME.FORM_STEP_3, 3)"
            matTooltip="{{ 'GUIDE.SHOW_INSTRUCTIONS' | translate }}"
            matTooltipClass="universalTooltip"
            matTooltipPosition="right"
            class="guide-open-button"
            fontSet="material-icons-outlined">
            live_help
          </mat-icon>
        </ng-template>
        <ng-template matStepContent>
          <app-temp-premit-step3
            [formGroup]="stepControl"
            [mode]="mode"
            (firstInputShiftTabPressed)="mainStepper.previous()"
            (lastFieldTabPressed)="mainStepper.next()">
          </app-temp-premit-step3>
        </ng-template>
      </mat-step>
    </ng-container>

    <ng-container *ngIf="getStepOfForm('step4') as stepControl">
      <mat-step
        [stepControl]="stepControl"
        [completed]="stepControl.valid"
        [state]="
          mainStepper.selectedIndex === 3
            ? 'edit'
            : stepControl.touched && stepControl.valid
            ? 'done'
            : stepControl.touched && stepControl.invalid
            ? 'warn'
            : stepControl.pristine && stepControl.invalid
            ? 'number'
            : stepControl.pristine && stepControl.valid
            ? 'done'
            : 'number'
        ">
        <ng-template matStepLabel>
          {{ 'PERSONAL_DATA_FORM.TRAVELS-AND-STAYS' | translate }}
          <mat-icon
            (click)="initGuide(GUIDE_NAME.FORM_STEP_4, 4)"
            matTooltip="{{ 'GUIDE.SHOW_INSTRUCTIONS' | translate }}"
            matTooltipClass="universalTooltip"
            matTooltipPosition="right"
            class="guide-open-button"
            fontSet="material-icons-outlined">
            live_help
          </mat-icon>
        </ng-template>
        <ng-template matStepContent>
          <app-temp-premit-step4
            [formGroup]="stepControl"
            (firstInputShiftTabPressed)="mainStepper.previous()"
            (lastFieldTabPressed)="mainStepper.next()">
          </app-temp-premit-step4>
        </ng-template>
      </mat-step>
    </ng-container>

    <ng-container *ngIf="getStepOfForm('step5') as stepControl">
      <mat-step
        [stepControl]="stepControl"
        [completed]="stepControl.valid"
        [state]="
          mainStepper.selectedIndex === 4
            ? 'edit'
            : stepControl.touched && stepControl.valid
            ? 'done'
            : stepControl.touched && stepControl.invalid
            ? 'warn'
            : stepControl.pristine && stepControl.invalid
            ? 'number'
            : stepControl.pristine && stepControl.valid
            ? 'done'
            : 'number'
        ">
        <ng-template matStepLabel>
          {{ 'PERSONAL_DATA_FORM.FAMILY-MEMBERS-LIVING-IN-POLAND' | translate }}
        </ng-template>
        <ng-template matStepContent>
          <app-temp-premit-step5
            [formGroup]="stepControl"
            [mode]="mode"
            [userProcess]="userProcess"
            (toggleVerificationOfPersonalDetails)="toggleVerificationOfPersonalDetails.emit()"
            (firstInputShiftTabPressed)="mainStepper.previous()"
            (lastFieldTabPressed)="saveAndProceedButton.focus()">
          </app-temp-premit-step5>
        </ng-template>
      </mat-step>
    </ng-container>
  </mat-stepper>
</div>

<div class="save-and-proceed-container" [ngClass]="{ 'small-view': isSmallView }" *ngIf="mode === 'foreigner'">
  <div class="overall-controls" *ngIf="personalDataForm">
    <div class="whole-http-error-container" *ngIf="errorMessage">
      <mat-icon>error</mat-icon>
      {{ 'APPLICATION.ERROR-VALIDATING-DATA' | translate }}
    </div>
    <mat-spinner id="bottomBarLoadingIndicator" [diameter]="30" *ngIf="loadingUserProcess"></mat-spinner>

    <div
      matTooltip="{{ 'APPLICATION.PLEASE-VERIFY-DATA-IN-FORM' | translate }}"
      matTooltipClass="universalTooltip"
      [matTooltipDisabled]="!personalDataForm.invalid"
      class="save-and-proceed-button-wrapper">
      <button
        class="save-and-proceed-button"
        #saveAndProceedButton
        mat-flat-button
        [disabled]="personalDataForm.invalid || !!errorMessage || loadingUserProcess"
        (click)="saveAndProceed()">
        {{ 'APPLICATION.SAVE-AND-PROCEED-TO-DOCUMENTS' | translate }}
      </button>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="spinner-container">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
