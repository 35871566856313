import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { PartnerCodesHttpService } from '@core/http/partner-codes.http.service';
import { SnackbarService } from '@shared/snack-bar/snack-bar.service';
import * as pcActions from './partner-codes.actions';

@Injectable()
export class PartnerCodesEffects {
  constructor(
    private actions$: Actions,
    private http: PartnerCodesHttpService,
    private snackService: SnackbarService
  ) {}

  searchPartners$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pcActions.searchPartners),
      switchMap(({ opts }) => {
        return this.http.searchPartners(opts).pipe(
          map(partners => pcActions.searchPartnersSuccess({ partners })),
          catchError(() => {
            this.snackService.showError('MANAGEMENT.PARTNER.ERROR_SEARCHING_PARTNERS');
            return of(pcActions.searchPartnersError());
          })
        );
      })
    )
  );

  searchPayouts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pcActions.searchPayouts),
      switchMap(({ opts }) => {
        return this.http.searchPayouts(opts).pipe(
          map(payouts => pcActions.searchPayoutsSuccess({ payouts })),
          catchError(() => {
            this.snackService.showError('MANAGEMENT.PARTNER.ERROR_SEARCHING_PAYOUTS');
            return of(pcActions.searchPayoutsError());
          })
        );
      })
    )
  );

  getPartnerCodeDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pcActions.getPartnerCodeDetails),
      switchMap(({ userId }) => {
        return this.http.getCodeOfPartner(userId).pipe(
          map(discountCode => pcActions.getPartnerCodeDetailsSuccess({ userId, discountCode })),
          catchError(() => {
            this.snackService.showError('MANAGEMENT.PARTNER.ERROR_FETCHING_DISCOUNT_CODE_DETAILS');
            return of(pcActions.getPartnerCodeDetailsError());
          })
        );
      })
    )
  );

  generatePartnerCodeForUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pcActions.generatePartnerCodeForUser),
      switchMap(({ userId }) => {
        return this.http.generatePartnerCodeForUser(userId).pipe(
          map(discountCode => pcActions.generatePartnerCodeForUserSuccess({ discountCode })),
          catchError(() => {
            this.snackService.showError('MANAGEMENT.PARTNER.ERROR_CREATING_DISCOUNT_CODE');
            return of(pcActions.generatePartnerCodeForUserError());
          })
        );
      })
    )
  );

  regeneratePartnerCodeForUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pcActions.regeneratePartnerCodeForUser),
      switchMap(({ userId }) => {
        return this.http.regenerateCodeForPartner(userId).pipe(
          map(discountCode => pcActions.regeneratePartnerCodeForUserSuccess({ discountCode })),
          catchError(() => {
            this.snackService.showError('MANAGEMENT.PARTNER.ERROR_REGENERATING_DISCOUNT_CODE');
            return of(pcActions.regeneratePartnerCodeForUserError());
          })
        );
      })
    )
  );

  setDifferentCodeForPartner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pcActions.setDifferentCodeForPartner),
      switchMap(({ userId, code }) => {
        return this.http.setDifferentCodeForPartner(userId, code).pipe(
          map(discountCode => pcActions.setDifferentCodeForPartnerSuccess({ discountCode })),
          catchError(() => {
            this.snackService.showError('MANAGEMENT.PARTNER.ERROR_SETTING_DISCOUNT_CODE_TRY_DIFFERENT_ONE');
            return of(pcActions.setDifferentCodeForPartnerError());
          })
        );
      })
    )
  );

  editPartnerCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pcActions.editPartnerCode),
      switchMap(({ opts }) => {
        return this.http.editCodeOfPartner(opts).pipe(
          map(discountCode => pcActions.editPartnerCodeSuccess({ discountCode })),
          catchError(() => {
            this.snackService.showError('MANAGEMENT.PARTNER.ERROR_EDITING_DISCOUNT_CODE');
            return of(pcActions.editPartnerCodeError());
          })
        );
      })
    )
  );

  getPartnerPointsSum$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pcActions.getPartnerPointsSum),
      switchMap(({ userId }) => {
        return this.http.getPartnerPointsSum(userId).pipe(
          map(sumOfPoints => pcActions.getPartnerPointsSumSuccess({ userId, sumOfPoints })),
          catchError(() => {
            this.snackService.showError('MANAGEMENT.PARTNER.ERROR_FETCHING_PARTNER_POINTS_SUM');
            return of(pcActions.getPartnerPointsSumError());
          })
        );
      })
    )
  );

  getPartnerCodeUsage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pcActions.getPartnerCodeUsage),
      switchMap(({ userId }) => {
        return this.http.getPartnerCodeUsage(userId).pipe(
          map(purchases => pcActions.getPartnerCodeUsageSuccess({ userId, purchases })),
          catchError(() => {
            this.snackService.showError('MANAGEMENT.PARTNER.ERROR_FETCHING_PARTNER_CODE_USAGE');
            return of(pcActions.getPartnerCodeUsageError());
          })
        );
      })
    )
  );

  getPartnerPayouts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pcActions.getPartnerPayoutsHistory),
      switchMap(({ userId }) => {
        return this.http.getPartnerPayoutsHistory(userId).pipe(
          map(partnerPayouts => pcActions.getPartnerPayoutsHistorySuccess({ userId, partnerPayouts })),
          catchError(() => {
            this.snackService.showError('MANAGEMENT.PARTNER.ERROR_FETCHING_PARTNER_PAYOUTS_HISTORY');
            return of(pcActions.getPartnerPayoutsHistoryError());
          })
        );
      })
    )
  );

  changePayoutStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pcActions.changePayoutStatus),
      switchMap(({ opts }) => {
        return this.http.changePayoutStatus(opts).pipe(
          map(partnerPayout => pcActions.changePayoutStatusSuccess({ partnerPayout })),
          catchError(() => {
            this.snackService.showError('MANAGEMENT.PARTNER.ERROR_CHANGING_PAYOUT_STATUS');
            return of(pcActions.changePayoutStatusError());
          })
        );
      })
    )
  );

  cancelPayout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pcActions.cancelPayout),
      switchMap(({ opts }) => {
        return this.http.cancelPayout(opts).pipe(
          map(({ success }) => pcActions.cancelPayoutSuccess({ payoutId: opts.payoutId, success })),
          catchError(() => {
            this.snackService.showError('MANAGEMENT.PARTNER.ERROR_CANCELLING_PAYOUT');
            return of(pcActions.cancelPayoutError());
          })
        );
      })
    )
  );

  getAllPartnerPoints$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pcActions.getAllPartnerPoints),
      switchMap(({ userId }) => {
        return this.http.getAllPartnerPoints(userId).pipe(
          map(partnerPoints => pcActions.getAllPartnerPointsSuccess({ partnerPoints })),
          catchError(() => {
            this.snackService.showError('MANAGEMENT.PARTNER.ERROR_FETCHING_ALL_PARTNER_POINTS_LIST');
            return of(pcActions.getAllPartnerPointsError());
          })
        );
      })
    )
  );

  changePointsStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pcActions.changePointsStatus),
      switchMap(({ opts }) => {
        return this.http.changePointsStatus(opts).pipe(
          map(partnerPoints => pcActions.changePointsStatusSuccess({ partnerPoints })),
          catchError(() => {
            this.snackService.showError('MANAGEMENT.PARTNER.ERROR_CHANGING_POINTS_STATUS');
            return of(pcActions.changePointsStatusError());
          })
        );
      })
    )
  );

  addPartnerPointsManually$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pcActions.addPartnerPointsManually),
      switchMap(({ opts }) => {
        return this.http.addPartnerPointsManually(opts).pipe(
          map(partnerPoints => pcActions.addPartnerPointsManuallySuccess({ partnerPoints })),
          catchError(() => {
            this.snackService.showError('MANAGEMENT.PARTNER.ERROR_ADDING_PARTNER_POINTS_MANUALLY');
            return of(pcActions.addPartnerPointsManuallyError());
          })
        );
      })
    )
  );

  removePartnerPoints$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pcActions.removePartnerPoints),
      switchMap(({ opts }) => {
        return this.http.removePartnerPoints(opts).pipe(
          map(({ removedPointsId }) => pcActions.removePartnerPointsSuccess({ removedPointsId })),
          catchError(() => {
            this.snackService.showError('MANAGEMENT.PARTNER.ERROR_ADDING_REMOVING_POINTS');
            return of(pcActions.removePartnerPointsError());
          })
        );
      })
    )
  );

  getGlobalTerms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pcActions.getGlobalTerms),
      switchMap(() => {
        return this.http.getGlobalTerms().pipe(
          map(globalTerms => pcActions.getGlobalTermsSuccess({ globalTerms })),
          catchError(() => {
            this.snackService.showError('MANAGEMENT.PARTNER.ERROR_FETCHING_GLOBAL_PARTNERS_TERMS');
            return of(pcActions.getGlobalTermsError());
          })
        );
      })
    )
  );

  updateGlobalTerms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pcActions.updateGlobalTerms),
      switchMap(({ opts }) => {
        return this.http.updateGlobalTerms(opts).pipe(
          map(globalTerms => pcActions.updateGlobalTermsSuccess({ globalTerms })),
          catchError(() => {
            this.snackService.showError('MANAGEMENT.PARTNER.ERROR_UPDATING_GLOBAL_TERMS');
            return of(pcActions.updateGlobalTermsError());
          })
        );
      })
    )
  );
}
